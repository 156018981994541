<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability'

defineOptions({
  name: 'CyclabilitySidebarSegment',
})

const props = defineProps<{
  data: CyclabilityData
}>()

const { t } = useI18n()
const { selectedArea, unselectAllAreas } = useSelectedAreaFromUrl()

const selectedSegment = computed(() => {
  const features = props.data.roads?.geojson.features || []
  return selectedArea.value
    ? features.find(f => f.properties?.name === selectedArea.value[0])
    : undefined
})

const poisInSegment = computed(() => {
  const features = props.data.pois?.geojson.features || []
  const index = selectedArea.value
    ? features.findIndex(f => f.properties?.name === selectedArea.value[0])
    : undefined

  if (index !== undefined) {
    // get the next 3 pois
    return features.slice(index, index + 4).map(f => f.properties || {})
  } else {
    return []
  }
})
</script>

<template>
  <MapSidebar v-if="selectedArea.length > 0">
    <div class="rounded-md bg-white mb-8 p-8">
      <h4 class="font-bold gap-2 mb-4 text-lg">
        <DIcon
          name="chevron-left"
          @click="unselectAllAreas"
        />

        {{ t('Segment selected') }}
      </h4>

      <template v-if="selectedSegment?.properties?.scoreValue">
        <h5 class="font-bold">
          {{ t('CycleRAP score') }}
        </h5>
        <DColored
          :value="selectedSegment?.properties?.score - 1"
          :colors="PALETTE_COLORS_CYCLERAP"
          class="text-3xl font-black"
          as="p"
        >
          {{ formatNumber(selectedSegment?.properties?.scoreValue) }}
        </DColored>
      </template>
    </div>

    <template v-if="selectedSegment?.properties?.treatment">
      <h5 class="leading-4 my-4 font-bold text-lg">
        {{ t('Suggested treatments') }}
      </h5>

      <DColored
        v-if="selectedSegment?.properties?.treatment"
        :value="selectedSegment?.properties?.treatmentScoreRisk - 1"
        :colors="PALETTE_COLORS_CYCLERAP"
        no-style
      >
        <template #default="{ style }">
          <div
            class="border-l-8 border-red bg-white px-8 py-4"
            :style="{ borderColor: style.color }"
          >
            <p>
              {{ t(`cyclability.data.treatment.${selectedSegment?.properties?.treatment}`) }}
            </p>

            <h4 class="font-bold mt-4">
              {{ t('Score improvement') }}
            </h4>

            <p :style="style">
              <DTooltip
                is="span"
                v-if="selectedSegment?.properties?.treatmentImpact"
                title="Impact of treatment"
                class="text-3xl font-black"
              >
                -{{ formatNumber(selectedSegment?.properties?.treatmentImpact) }}
              </DTooltip>

              <span
                v-if="selectedSegment?.properties?.treatmentScoreValue"
                class="text-xl font-bold"
              >
                (<DTooltip
                  is="span"
                  title="Score after treatment"
                >
                  {{ formatNumber(selectedSegment?.properties?.treatmentScoreValue) }}
                </DTooltip>)
              </span>
            </p>
          </div>
        </template>
      </DColored>
    </template>

    <h5 class="leading-4 my-4 font-bold text-lg mt-8">
      {{ t('Cyclist View') }}<br>
      <small class="font-thin">{{ t('4 panoramas') }}</small>
    </h5>

    <div
      v-for="poi in poisInSegment"
      :key="poi.name"
      class="aspect-video w-full h-auto overflow-auto rounded-md bg-slate-200 mb-4"
    >
      <DAsyncImg :src="poi.path" />
    </div>
  </MapSidebar>
</template>
