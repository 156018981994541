<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

defineOptions({
  name: 'CityCyclabilityPassagesMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityPassages')

const dateRange = {
  start: new Date(formatDate('2024-04-29', DATE_FORMAT)),
  end: new Date(formatDate('2024-06-29', DATE_FORMAT)),
}

const { translateDate } = useDateTranslation()

function translateRange(range: DateRange) {
  return {
    start: translateDate(new Date(range.start), DATES_FORMATS.FULL),
    end: translateDate(new Date(range.end), DATES_FORMATS.FULL),
  }
}

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    date: dateRange.start.toString(),
    daily: {
      __typename: 'Percent',
      value: 10107,
      trend: {
        percent: 6,
        direction: Directions.UP,
        positive: true,
      },
    },
    maximum: {
      __typename: 'Percent',
      value: 15374,
      trend: {
        percent: 4,
        direction: Directions.UP,
        positive: true,
      },
    },
    total: {
      __typename: 'Percent',
      value: 626641,
      trend: {
        percent: 17,
        direction: Directions.UP,
        positive: true,
      },
    },
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityPassages"
    :used-filters="['dateRange']"
  >
    <template #title>
      {{ t('cyclability.cycling_passages.title') }}
    </template>

    <template #range-subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }} - {{ t('(VS same last period) - from Eco-Compteur') }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 justify-between">
        <div>
          <p class="text-sm">
            {{ t('cyclability.cycling_passages.daily') }}
          </p>
          <TrendMetric :data="data.daily" />
        </div>
        <div>
          <p>{{ t('cyclability.cycling_passages.maximum') }}</p>
          <TrendMetric :data="data.maximum" />
        </div>
        <div>
          <p>{{ t('cyclability.cycling_passages.total') }}</p>
          <TrendMetric :data="data.total" />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
