import { GeometriesTypes, type GeometryType } from '@/types/index.types'
import { useMagicKeys } from '@vueuse/core'

const NAME_SEPARATOR = '::'
const SPACE_SEPARATOR = '%%'
const SEPARATOR = '__'

type MaybeGeometryType = GeometryType | null | undefined

function isHexGeometry(type?: MaybeGeometryType) {
  return type?.indexOf('h3_') === 0
}

// Areas are encoded as `type'id` where `type` is the geometry type and `id` is the area id
export function getGeometryTypeFromUrl(areas?: string): GeometryType {
  const id = areas?.split(SEPARATOR)[0]

  if (!id) {
    return GeometriesTypes.H3_8
  }

  const [type] = id.split(NAME_SEPARATOR) as [GeometryType]
  return type || GeometriesTypes.H3_8
}

export function useSelectedAreaFromUrl(geometryType?: Ref<MaybeGeometryType> | MaybeGeometryType) {
  const route = useRoute()
  const router = useRouter()
  const { control, command } = useMagicKeys()

  if (!isRef(geometryType)) {
    geometryType = ref(geometryType)
  }

  const selectedArea = computed({
    set(v: string[]) {
      router.push({
        params: {
          areas: v.length ? v.map(n => encodeAreaName(n)).join(SEPARATOR) : '',
        },
      })
    },
    get() {
      const areas = Array.isArray(route.params.areas) ? route.params.areas[0] : route.params.areas
      if (!areas || areas === '') {
        return []
      }

      return areas.split(SEPARATOR).map((name: string) => decodeAreaId(name))
    },
  })

  function getAreaName(id: string | number, separator = NAME_SEPARATOR) {
    const isHex = isHexGeometry(get(geometryType))
    return `${isHex ? `HEX${separator}` : ''}${id}`
  }

  function encodeAreaName(id: string | number, type?: MaybeGeometryType) {
    const typeRef = type || get(geometryType)

    if (!typeRef) {
      return encodeURIComponent(`${id}`.replace(' ', SPACE_SEPARATOR))
    }

    return encodeURIComponent(
      `${typeRef}${NAME_SEPARATOR}${id}`.replace(' ', SPACE_SEPARATOR),
    )
  }

  function decodeAreaId(name: string) {
    const id = name.includes(NAME_SEPARATOR) ? name.split(NAME_SEPARATOR)[1] : name
    return decodeURIComponent(id).replace(SPACE_SEPARATOR, ' ')
  }

  function onSelectArea(name?: string) {
    if (name) {
      if (get(command) || get(control)) {
        if (selectedArea.value.includes(name)) {
          set(
            selectedArea,
            get(selectedArea).filter(n => n !== name),
          )
        } else {
          set(selectedArea, [...get(selectedArea), name])
        }
      } else {
        if (
          selectedArea.value.length === 1
          && selectedArea.value.includes(name)
        ) {
          set(selectedArea, [])
        } else {
          set(selectedArea, [name])
        }
      }
    }
  }

  function unselectAllAreas() {
    set(selectedArea, [])
  }

  watch(geometryType, unselectAllAreas)

  return {
    selectedArea,
    onSelectArea,
    unselectAllAreas,
    getAreaName,
  }
}
