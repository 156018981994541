<script setup lang="ts">
// import type { ClassBreaks } from '@/composables/classBreaks'

defineOptions({
  name: 'CyclabilityMapPath',
})

const { t } = useI18n()
const navigationStore = useNavigationStore()
const cityStore = useCityStore()
const { slug } = storeToRefs(cityStore)
const { data, mapOptions, loading } = useCyclabilityData({
  excludeMetric: ['network'],
})
const { networkType } = useCyclability(slug, mapOptions)
const { pathsMetric, availablePathsMetrics } = useCyclabilityRoads(slug)
</script>

<template>
  <MapContainer>
    <Teleport
      v-if="navigationStore.filtersIsMounted"
      to="#teleport-sidebar"
    >
      <DSelectPicker
        v-if="availablePathsMetrics"
        v-model="pathsMetric"
        icon="lines"
        :options="availablePathsMetrics.map(({ name, label }) => ({ value: name, label }))"
        :label="t('Path metric')"
        :is-loading="loading"
      />

      <CyclabilityMapSettings :has-network-metric="false" />
    </Teleport>

    <CyclabilityMapSegments
      v-bind="mapOptions"
      :data="data"
      :is-loading="loading"
      :network-type="networkType"
      :paths-metric="pathsMetric"
      :legend-title="t('City Path Bikeability')"
      @on-zoom="(zoom: number) => {
        mapOptions.zoom = zoom
      }"
    />
  </MapContainer>
</template>
