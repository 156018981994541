export function useChartTooltip(options, datasets) {
  const { translateLabel } = useLabelTranslation()
  const { translateDate } = useDateTranslation()
  const { t, locale } = useI18n()

  const afterTitles = computed(() => ({
    avg(dataIndex) {
      const value = []

      get(datasets)
        .filter(d => d.label)
        .forEach((dataset) => {
          if (dataset.data.length && dataset.data[dataIndex]?.y) {
            value.push(Number.parseFloat(dataset.data[dataIndex].y))
          }
        })

      const avg = value.reduce((acc, v) => acc + v, 0) / value.length

      return [
        `${t('AVG')}: ${Intl.NumberFormat(locale.value).format(
          Math.round(avg),
        )}`,
      ]
    },
    sum({ dataset, dataIndex }) {
      const x = dataset?.data?.[dataIndex]?.x

      if (!x) {
        return
      }

      const total = get(datasets)
        .filter(({ label, data }) => {
          // filter out status dataset and weekend fill dataset
          if (!label || label === 'status') {
            return false
          }

          return data?.findIndex(d => d.x === x) >= 0
        })
        .reduce(
          (acc, { data }) =>
            acc + Number.parseFloat(data.find(d => d.x === x)?.y ?? 0),
          0,
        )

      return [
        `${t('Total')}: ${Intl.NumberFormat(locale.value).format(total)}`,
        '',
      ]
    },
  }))

  return computed(() => ({
    mode: 'point',
    position: 'nearest',
    backgroundColor: 'rgb(5, 8, 22, 0.8)',
    titleAlign: 'center',
    padding: { x: 17, y: 12 },
    filter({ dataset, label }) {
      return (
        (dataset.label
          && dataset.label !== 'fill'
          && dataset.label !== CHART_WEEKEND_FILL_LABEL)
          || typeof label === 'object'
      )
    },
    callbacks: {
      beforeTitle(context) {
        if (context.length) {
          const { dataIndex, dataset, label } = context[0]

          if (dataset.label === 'status') {
            return t('Service status')
          } else if (dataset.data[dataIndex].estimated || label.estimated) {
            return [t('Estimated'), '']
          }

          return false
        }
      },
      title(context) {
        if (context.length) {
          const { dataset, label } = context[0]
          const optionsRef = options.value

          if (
            dataset.label === 'status' // for status dataset
            || typeof label === 'object' // for pie chart
            || optionsRef?.isPercent // for percent chart
          ) {
            return false
          }

          const scalesX = optionsRef?.scales?.x || null

          if (scalesX && scalesX.type === 'time') {
            return translateDate(label, scalesX.time?.tooltipFormat || DATES_FORMATS.DEFAULT)
          }
        }
      },
      afterTitle(context) {
        if (context.length) {
          const optionsRef = options.value
          const { dataset } = context[0]

          if (dataset.label === 'status') {
            return null
          }

          if (optionsRef?.afterTitle) {
            return get(afterTitles)[optionsRef.afterTitle](context[0])
          }

          return false
        }
      },
      label({ dataset, dataIndex, parsed, label }) {
        if (dataset.label === 'status') {
          const status = dataset.status[dataIndex].status

          if (Array.isArray(status)) {
            return status.map(
              s =>
                `${s.code}${s.detail ? ` - ${s.detail}` : ''}${
                  s.since
                    ? ` (since ${
                      new Date(s.since).toISOString().split('T')[0]
                    })`
                    : ''
                }`,
            )
          } else {
            return (
              status
              && `${status.code}${
                status.detail ? ` - ${status.detail}` : ''
              } (since ${new Date(status.since).toISOString().split('T')[0]})`
            )
          }
        } else {
          let labelStr = ''
          const optionsRef = options.value

          if (Array.isArray(dataset.label) && dataset.label[dataIndex]) {
            labelStr = translateLabel(dataset.label[dataIndex], optionsRef?.dimension)
          } else {
            labelStr = translateLabel(dataset.label || label.label, optionsRef?.dimension)
          }

          const parsedValue = Number(parsed.y || parsed)

          if (optionsRef?.isPercent) {
            return ` ${labelStr}: ${formatPercent(parsedValue)}`
          }

          return ` ${labelStr}: ${Intl.NumberFormat(
            locale.value,
            optionsRef?.format,
          ).format(parsedValue)}`
        }
      },
    },
  }))
}
