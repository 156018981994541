<script setup>
const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  dateRange: {
    type: Object,
    default: null,
  },
  lang: {
    type: String,
    default: null,
  },
  modes: {
    type: Array,
    default: () => [],
  },
  tags: {
    type: Array,
    default: () => [],
  },
  cities: {
    type: Array,
    default: () => [],
  },
  providers: {
    type: Array,
    default: () => [],
  },
  isAny: {
    type: Object,
    default: () => ({
      tags: true,
      cities: true,
      providers: true,
    }),
  },
  vehicleTypes: {
    type: Array,
    default: () => VEHICLE_TYPES,
  },
})

const emit = defineEmits([
  'update:search',
  'update:modes',
  'update:tags',
  'update:cities',
  'update:providers',
  'update:dateRange',
  'update:lang',
  'toggle-any',
])

const { search, dateRange, lang, tags, cities, providers, modes } = useVModels(
  props,
  emit,
)

const { t } = useI18n()
const { translateDate } = useDateTranslation()

const dateRangeStr = computed({
  get() {
    return dateRangeToString(dateRange.value)
  },
})

function dateRangeToString(dateRange) {
  return dateRange
    ? `${translateDate(dateRange.start, HUMAN_DATE_FORMAT)} - ${translateDate(
      dateRange.end,
      HUMAN_DATE_FORMAT,
    )}`
    : ''
}
</script>

<template>
  <form
    class="flex flex-col gap-2"
    @submit.prevent
  >
    <DField>
      <template #label>
        <p class="text-grey-300">
          <DIcon
            path="target"
            size="xs"
          />{{ t('Search by title') }}
        </p>
      </template>
      <DInputText
        v-model.lazy.trim="search"
        :placeholder="t('Type any words')"
        type="search"
        size="sm"
      />
    </DField>

    <FilterLanguage v-model="lang" />

    <DPicker
      id="dateRange"
      :label="t('News from')"
      icon="calendar"
      :auto-close="false"
    >
      <template #text>
        <p class="whitespace-nowrap overflow-hidden text-ellipsis">
          {{ dateRangeStr }}
        </p>
      </template>

      <DateRangePicker v-model:date-range="dateRange" />
    </DPicker>

    <FilterByTags
      v-model:modes="modes"
      v-model:tags="tags"
      v-model:cities="cities"
      v-model:providers="providers"
      :vehicle-types="vehicleTypes"
      :is-any="isAny"
      @toggle-any="tagName => emit('toggle-any', tagName)"
    />
  </form>
</template>

<style lang="pcss" scoped>
.d-field {
  @apply bg-grey-50 hover:bg-blue-100 rounded-md py-1 px-2 !mb-0;
}
</style>
