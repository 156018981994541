const svgs = import.meta.glob('@/assets/svg/icons/*.svg', {
  eager: true,
  query: '?raw',
  import: 'default',
})

export const ICON_PATHS = Object.entries(svgs).reduce((acc, [key, svgStr]) => {
  const name = key.match(/\/([a-z-]+)\.svg/)?.[1]
  const path = svgStr.match(/ d="([0-9a-zA-Z., -]+)"/)?.[1]
  if (path && name) {
    acc[name] = path
  }

  return acc
}, {})
