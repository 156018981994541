<script setup lang="ts">
defineOptions({
  name: 'CyclabilityMapBikeability',
})

const { t } = useI18n()
const navigationStore = useNavigationStore()

const { data, mapOptions, loading } = useCyclabilityData({
  excludeData: ['roads'],
})
</script>

<template>
  <MapContainer>
    <Teleport
      v-if="navigationStore.filtersIsMounted"
      to="#teleport-sidebar"
    >
      <CyclabilityMapSettings />
    </Teleport>

    <CyclabilityMap
      :data="data"
      :is-loading="loading"
      v-bind="mapOptions"
      :legend-title="t('City Bikeability')"
      @on-zoom="(zoom) => {
        mapOptions.zoom = Math.floor(zoom)
      }"
    />
  </MapContainer>
</template>
