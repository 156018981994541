<script setup lang="ts">
import type { Map, MapLayerMouseEvent } from 'maplibre-gl'

defineOptions({
  name: 'MapSelectArea',
})

const props = defineProps({
  showButton: {
    type: Boolean,
    default: true,
  },
  layersIds: {
    type: Array as PropType<string[]>,
    required: true,
  },
})

const { layersIds } = toRefs(props)

const { t } = useI18n()
const map = inject<Ref<Map | null>>('map', ref(null))

const { selectedArea, onSelectArea, unselectAllAreas }
  = useSelectedAreaFromUrl()

function onFeatureClick(event: MapLayerMouseEvent) {
  const name = event.features?.[0].properties?.name

  onSelectArea(name)
}

watch(layersIds, (layersIds, lastLayersIds) => {
  if (lastLayersIds === undefined) {
    if (map.value) {
      layersIds.forEach((layerId) => {
        map.value?.on('click', layerId, onFeatureClick)
      })
    }

    return
  }

  const removedLayersIds = lastLayersIds.filter(layerId => !layersIds.includes(layerId))
  const addedLayersIds = layersIds.filter(layerId => !lastLayersIds.includes(layerId))

  if (map.value) {
    addedLayersIds.forEach((layerId) => {
      map.value?.on('click', layerId, onFeatureClick)
    })

    removedLayersIds.forEach((layerId) => {
      map.value?.off('click', layerId, onFeatureClick)
    })
  }
}, { immediate: true })
</script>

<template>
  <slot :unselect="() => unselectAllAreas() ">
    <MapLibreControl
      v-if="selectedArea.length !== 0"
      position="top-right"
      no-style
      class="rounded-full m-4"
    >
      <DButton
        v-if="showButton"
        variant="fill-primary"
        icon-left="cross"
        @click="unselectAllAreas"
      >
        {{ t('Unselect all') }}
      </DButton>
    </MapLibreControl>
  </slot>
</template>
