<script setup>
import newslinksQuery from '@/graphql/queries/newslinks.gql'

defineOptions({
  name: 'NewsfeedView',
})

const props = defineProps({
  infiniteTarget: {
    type: HTMLElement,
    default: null,
  },
})

const { t } = useI18n()
const route = useRoute()
const navigationStore = useNavigationStore()
const { relativeRanges } = useDateRangeFilter()

const { city } = storeToRefs(useCityStore())
const { provider } = storeToRefs(useProviderStore())

const perPage = 20
const page = ref(1)
const el = ref(null)

const vehicleTypesAvailable = computed(() => {
  const vehicleTypes = (get(provider) || get(city))?.vehicleTypes

  const vehicleTypesFiltered = vehicleTypes
    ?.map(v => (typeof v === 'object' ? v.type : v))
    .filter(type => DATA_VEHICLE_TYPES.includes(type))

  return (
    (vehicleTypesFiltered
      && Object.entries(ENUM_VEHICLE_TYPES).reduce((acc, entry) => {
        if (vehicleTypesFiltered?.includes(entry[1])) {
          acc.push(entry[0])
        }

        return acc
      }, []))
      || VEHICLE_TYPES
  )
})

const filters = reactive({
  search: '',
  dateRange: relativeRanges.lastYear.period,
  lang: null,
  modes: getVehicleTypesFromRoute(),
  tags: [],
  cities: [...(get(city) ? [get(city).wikidataId] : [])],
  providers: [...(get(provider) ? [get(provider).slug] : [])],
  isAny: {
    tags: true,
    cities: true,
    providers: true,
  },
})

const isFiltered = computed(() => {
  return (
    filters.search
    || filters.dateRange
    || filters.lang !== null
    || filters.modes.length !== VEHICLE_TYPES.length
    || filters.tags.length
    || filters.cities.length
    || filters.providers.length
  )
})

function getVariables() {
  const vars = {
    page: page.value,
    perPage,
    text: filters.search || null,
    anyVehicleTypes: filters.modes?.map(mode => ENUM_VEHICLE_TYPES[mode]),
    anyTags: filters.isAny.tags ? filters.tags : null,
    anyCities: filters.isAny.cities ? filters.cities : null,
    anyProviders: filters.isAny.providers ? filters.providers : null,
    allTags: !filters.isAny.tags ? filters.tags : null,
    allCities: !filters.isAny.cities ? filters.cities : null,
    allProviders: !filters.isAny.providers ? filters.providers : null,
    langs: filters.lang ? [filters.lang] : [],
    publishedAfter: formatDate(filters.dateRange?.start, DATE_FORMAT) || null,
    publishedBefore: formatDate(filters.dateRange?.end, DATE_FORMAT) || null,
  }

  return Object.entries(vars).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value
    } else {
      acc[key] = undefined
    }

    return acc
  }, {})
}

const { result, loading, fetchMore, refetch, load } = useLazyQuery(
  newslinksQuery,
  getVariables(),
  {
    debounce: 500,
  },
)

const maxNbrPage = computed(() => {
  return Math.ceil(result.value?.newslinks?.total / perPage)
})
const news = computed(() => result.value?.newslinks?.newslinks || [])

const scroller = reactive(useScroll(el))
const { top } = useElementBounding(el)

// Infinite scroll configuration
whenever(
  () => props.infiniteTarget,
  target => useInfiniteScroll(target, onLoadMore, { distance: 10 }),
  { immediate: true },
)

watchDebounced(
  scroller,
  async () => {
    // force resize to update the top position of children
    nextTick()
    forceResize()
  },
  { debounce: 300, maxWait: 1000 },
)

watch(
  filters,
  async () => {
    set(page, 1)

    await nextTick()
    if (!get(loading)) {
      refetch(getVariables())
    }
  },
  { deep: true },
)

watch(
  () => route.params.mode,
  (mode) => {
    filters.modes = getVehicleTypesFromRoute(mode)
  },
  { immediate: true },
)

onMounted(() => {
  load()

  globalEmitter.on('filters:reset', () => {
    filters.search = ''
    filters.dateRange = relativeRanges.lastYear.period
    filters.lang = null
    filters.modes = getVehicleTypesFromRoute()
    filters.tags = []
    filters.cities = [...(get(city) ? [get(city).wikidataId] : [])]
    filters.providers = [...(get(provider) ? [get(provider).slug] : [])]
    filters.isAny = {
      tags: true,
      cities: true,
      providers: true,
    }
  })
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})

function getVehicleTypesFromRoute() {
  const mode = route.params.mode

  if (mode) {
    if (mode !== VEHICLE_TYPE_ALL) {
      return [mode]
    } else {
      return get(vehicleTypesAvailable)
    }
  }

  return get(vehicleTypesAvailable)
}

function onLoadMore() {
  page.value += 1

  if (maxNbrPage.value < page.value || loading.value) {
    return
  }

  fetchMore({
    variables: getVariables(),
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const newResults = {
        ...previousResult,
        newslinks: {
          ...fetchMoreResult.newslinks,
          newslinks: [
            ...previousResult.newslinks.newslinks,
            ...fetchMoreResult.newslinks.newslinks,
          ],
        },
      }

      return newResults
    },
  })
}
</script>

<template>
  <div class="flex h-full flex-col">
    <BoardTitle hide-on-mobile>
      {{ t('Newsfeed') }}
      <FiltersIcons
        :filters="['search', 'dateRange', 'language', 'filterBy']"
      />
    </BoardTitle>

    <Teleport
      v-if="navigationStore.filtersIsMounted"
      to="#teleport-sidebar"
    >
      <NewsfeedFilters
        v-model:search="filters.search"
        v-model:date-range="filters.dateRange"
        v-model:lang="filters.lang"
        v-model:modes="filters.modes"
        v-model:tags="filters.tags"
        v-model:cities="filters.cities"
        v-model:providers="filters.providers"
        :is-any="filters.isAny"
        @toggle-any="key => (filters.isAny[key] = !filters.isAny[key])"
      />
    </Teleport>

    <div class="-mx-4 flex-1 px-4">
      <div
        v-if="news.length"
        class="flex flex-col gap-4"
      >
        <NewslinkCard
          v-for="item in news"
          :key="item.id"
          :news="item"
          :container-top="top"
          :filter-tags="filters.tags"
          :filter-cities="filters.cities"
          :filter-providers="filters.providers"
          :filter-vehicle-types="
            filters.modes && filters.modes.length !== VEHICLE_TYPES.length
              ? filters.modes?.map(mode => ENUM_VEHICLE_TYPES[mode])
              : []
          "
        />
      </div>

      <div
        v-else-if="!loading"
        class="mx-auto text-center"
      >
        <template v-if="isFiltered">
          <p class="text-xl font-bold uppercase text-grey-400">
            {{ t('No news found') }}
          </p>
          <p class="px-12 text-sm text-grey-400">
            {{ t('Please adjust your filters.') }}
          </p>
        </template>
        <template v-else>
          <p class="text-xl font-bold uppercase text-grey-400">
            {{ t('No news') }}
          </p>
          <p class="px-12 text-sm text-grey-400">
            {{ t('Sorry due to an error, news are unavailable right now!') }}
          </p>
        </template>
      </div>

      <DLoader v-if="loading" />
    </div>
  </div>
</template>
