<script setup lang="ts">
import Barcelona from './cities/Barcelona.vue'
import Sarajevo from './cities/Sarajevo.vue'

defineOptions({
  name: 'CyclabilityOverview',
})

const { t } = useI18n()

const availableScreenCities: Record<string, Component> = {
  barcelona: Barcelona,
  sarajevo: Sarajevo,
}

const cityStore = useCityStore()
const { slug, city, isLoading } = storeToRefs(cityStore)
</script>

<template>
  <div class="overview">
    <BoardTitle
      v-if="!isLoading"
      hide-on-mobile
    >
      {{ t('cyclability.city_cycling_title', { city: city?.nameLocalized }) }}
    </BoardTitle>

    <component
      :is="availableScreenCities[slug]"
      v-if="slug && availableScreenCities[slug]"
    />
  </div>
</template>
