import type { VehicleTypeData } from '@/types/vehicleTypes.types'
import { defineStore } from 'pinia'

export interface CityData {
  name: string
  country: string
  countryCode: string
  wikidataId: string
  continent: string
  region: string
  timezone: string
  default_zoom: number
  unlocked: boolean
  center: { lat: number, lng: number }
  countryLocalized: string
  nameLocalized: string
  regionLocalized: string
  vehicleTypes: {
    type: VehicleTypeData
    filtered: string[]
  }[]
}

export const useCitiesStore = defineStore('cities', () => {
  const i18n = useI18n()

  const {
    translateCity,
    translateCountry,
    translateRegion,
    translateWikidataId,
    translationDataExists,
  } = useLabelTranslation(i18n)

  const cities = shallowRef<CityData[]>([])

  const citiesByName = computed(() => {
    return get(cities).reduce((acc, city) => {
      acc[city.name] = city

      return acc
    }, {} as Record<string, CityData>)
  })

  const citiesByWikidataId = computed(() => {
    return get(cities).reduce((acc, city) => {
      if (city.wikidataId) {
        acc[city.wikidataId] = city
      }

      return acc
    }, {} as Record<string, CityData>)
  })

  const citiesByCountries = computed(() => {
    return get(cities).reduce((acc, city) => {
      if (!acc[city.country]) {
        acc[city.country] = []
      }

      acc[city.country].push(city)

      return acc
    }, {} as Record<string, CityData[]>)
  })

  const citiesByRegion = computed(() => {
    return get(cities).reduce((acc, city) => {
      if (!acc[city.region]) {
        acc[city.region] = []
      }

      acc[city.region].push(city)

      return acc
    }, {} as Record<string, CityData[]>)
  })

  const countriesByRegion = computed(() => {
    return get(cities).reduce((acc, city) => {
      if (!acc[city.region]) {
        acc[city.region] = []
      }

      if (!acc[city.region].includes(city.country)) {
        acc[city.region].push(city.country)
      }

      return acc
    }, {} as Record<string, string[]>)
  })

  const getCity = computed(() => {
    const citiesByNameRef = citiesByName.value
    return (name: string) => citiesByNameRef[name] || null
  })

  const getCityByWikidataId = computed(() => {
    const citiesByWikidataIdRef = citiesByWikidataId.value
    return (id: string) => citiesByWikidataIdRef[id] || null
  })

  const getCityNameLocalized = (name: string) => {
    const cityDiveCity = getCity.value(name)

    if (cityDiveCity?.nameLocalized) {
      return cityDiveCity.nameLocalized
    }

    return translateCity(name)
  }

  const getCountryNameLocalized = computed(() => {
    const citiesByCountriesRef = citiesByCountries.value

    return (countryName: string) => {
      const cities = citiesByCountriesRef[countryName]
      return cities ? translateCountry(cities[0].countryCode) : translateCountry(countryName)
    }
  })

  const getCountryCode = computed(() => {
    const citiesByCountriesRef = citiesByCountries.value

    return (countryName: string): string | undefined => citiesByCountriesRef[countryName]?.[0]?.countryCode
  })

  const isCityLocked = computed(() => {
    const citiesByNameRef = get(citiesByName)
    return (name: string) => citiesByNameRef[name]?.unlocked === false
  })

  function localizeCities(cities: CityData[]) {
    return cities.map((city) => {
      let nameLocalized = translateCity(city.name)

      if (
        !translationDataExists('cities', city.name, false)
        && city.wikidataId
        && translationDataExists('wikidata', city.wikidataId, false)
      ) {
        nameLocalized = translateWikidataId(city.wikidataId)
      } else if (translationDataExists('cities', city.name)) {
        nameLocalized = translateCity(city.name)
      } else if (city.wikidataId && translationDataExists('wikidata', city.wikidataId)) {
        nameLocalized = translateWikidataId(city.wikidataId)
      }

      return {
        ...city,
        nameLocalized,
        countryLocalized: translateCountry(city.countryCode?.trim()),
        regionLocalized: translateRegion(city.region?.trim()),
      }
    })
  }

  function saveCities(newCities: CityData[] = []) {
    cities.value = localizeCities(newCities).map((city) => {
      return {
        ...city,
        default_zoom: (city.default_zoom ?? 0) - 1 || 7,
      }
    }) || []
  }

  function $reset() {
    set(cities, [])
  }

  watch(i18n.locale, () => {
    cities.value = localizeCities(cities.value)
  })

  return {
    cities,
    citiesByName,
    citiesByWikidataId,
    citiesByCountries,
    citiesByRegion,
    countriesByRegion,
    getCity,
    getCityByWikidataId,
    getCityNameLocalized,
    getCountryNameLocalized,
    getCountryCode,
    isCityLocked,
    saveCities,
    $reset,
  }
})

// make sure to pass the right store definition, `useCitiesStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCitiesStore, import.meta.hot))
}
