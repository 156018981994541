import type { Color, ColorOrPattern } from '@/utils/colors'
import type { ScaleOptions } from 'chart.js'

export enum DimensionTypes {
  AUTO = 'auto',
  LOCATION = 'location',
  PROVIDER = 'provider',
  VEHICLETYPE = 'vehicleType',
  DATE = 'date',
  UNKNOWN = 'unknown',
}
export type DimensionType = `${DimensionTypes}`

interface SerieBase {
  name?: string
  label?: string
  category?: string
  color?: ColorOrPattern | ColorOrPattern[]
  status?: string
  unlocked?: boolean
  dataIndex?: number
}

export interface Serie extends SerieBase {
  data: (number | [number, number] | [number, number, boolean])[]
}

export interface SeriePercent extends SerieBase {
  percent: number
  value: number
  estimated?: boolean
}

export interface NotEstimatedSerie extends Serie {
  data: (number | [number, number])[]
}

type Estimated = {
  estimated: boolean
} | {
  data: [number, number, boolean][]
}

export type EstimatedSerie = Serie & Estimated
export type MaybeEstimatedSerie = Serie | EstimatedSerie

export type AnySerie = MaybeEstimatedSerie | SeriePercent

export enum TimeModes {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
export type TimeMode = `${TimeModes}`

export interface MetricDataSeries {
  labels?: string[]
  categories?: string[]
  series: MaybeEstimatedSerie[]
}

export interface MetricOptions {
  dimension?: DimensionType
  label?: (d: any, dataIndex?: number) => string | string
  color?: (d: any, dataIndex?: number) => Color | Color
  colors?: Color[]
  scales?: Record<string, ScaleOptions>
  timeMode?: TimeMode
  isCompare?: boolean
}

export interface MetricChart<T = MetricOptions> {
  data: MetricDataSeries
  type?: 'category' | 'time'
  options?: T
  legend?: boolean
}

export interface MetricPercentChart<T = MetricOptions> {
  data: SeriePercent[]
  options?: T
  legend?: boolean
}

export interface BarMetricOptions extends MetricOptions {
  unstacked?: boolean
}

export interface LineMetricOptions extends MetricOptions {
  elements?: any
}

export interface PieMetricOptions extends MetricOptions {
  elements?: any
}

export interface ChartTimeUnit {
  unit: string
  stepSize?: number
  displayFormats?: any
}
