<script setup lang="ts">
defineOptions({
  name: 'CyclabilityMapSettings',
})

const props = withDefaults(defineProps<{
  hasNetworkMetric?: boolean
}>(), {
  hasNetworkMetric: true,
})

const { t } = useI18n()
const cityStore = useCityStore()
const { slug, isLoading } = storeToRefs(cityStore)
const { mapOptions } = useCyclabilityMapOptions()

const {
  availableGeometries,
  availableAreasMetrics,
  areaActiveFile,
  geometryType,
  areasMetric,
  availableNetworks,
  availableNetworkMetrics,
  networkMetric,
  networkType,
} = useCyclability(slug, mapOptions)

onMounted(() => {
  globalEmitter.on('filters:reset', () => {
    geometryType.value = availableGeometries.value?.[0].name
    networkType.value = availableNetworks.value?.[0].name
  })

  if (!props.hasNetworkMetric) {
    networkMetric.value = null
  }
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})
</script>

<template>
  <DSelectPicker
    v-model="geometryType"
    icon="hexagones"
    :options="[
      {
        label: t('None'),
        value: null,
      },
      ...(availableGeometries.length > 1 ? [{
        label: t('Auto (zoom level)'),
        value: 'auto',
        default: true,
      }] : []),
      ...availableGeometries.map(({ name, label }) => ({ value: name, label })),
    ]"
    :label="t('Statistical area')"
    :is-loading="isLoading"
    :default-text="`${areaActiveFile?.name || geometryType} (auto)`"
  />

  <DSelectPicker
    v-if="geometryType && availableAreasMetrics"
    v-model="areasMetric"
    icon="hexagones"
    :options="availableAreasMetrics.map(({ name, label }) => ({ value: name, label }))"
    :label="t('Area metric')"
    :is-loading="isLoading"
  />

  <DSelectPicker
    v-model="networkType"
    icon="hexagones"
    :label="t('Cycling network')"
    :is-loading="isLoading"
    :options="[
      {
        label: t('None'),
        value: null,
      },
      ...availableNetworks.map(({ name, label }) => ({ value: name, label })),
    ]"
  />

  <DSelectPicker
    v-if="hasNetworkMetric && networkType && availableNetworkMetrics"
    v-model="networkMetric"
    icon="hexagones"
    :options="[
      {
        label: t('Road type'),
        value: null,
      },
      ...availableNetworkMetrics.map(({ name, label }) => ({ value: name, label })),
    ]"
    :label="t('Network metric')"
    :is-loading="isLoading"
  />
</template>
