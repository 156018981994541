<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability'
import type { LngLatLike } from 'maplibre-gl'

interface CyclabilityMapProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  isLoading?: boolean
}

defineOptions({
  name: 'CyclabilityMap',
})

withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['on-zoom'])

const { t } = useI18n()
const { selectedArea } = useSelectedAreaFromUrl()

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('on-zoom', zoom)"
  >
    <CellsGrid
      v-if="data.areas"
      :data="data.areas.data"
      :class-breaks="data.areas.classbreaks"
      :cells-type="data.areas.type"
      :selected="selectedArea"
    />

    <CityNetwork
      v-if="data.network"
      :network="data.network.roads"
      :data="data.network.data"
      :class-breaks="data.network.classbreaks"
      :metric="data.network.metric"
      :is-loading="isLoading"
    />

    <DefineTemplate>
      <DLoader v-if="isLoading" />
      <div class="flex gap-2">
        <div v-if="data.areas">
          <p class="mb-2">
            {{ t('Statistical area') }}
          </p>
          <MapLegend :class-breaks="data.areas.classbreaks">
            <template
              v-if="data.areas.labels"
              #label="{ index }"
            >
              {{ data.areas.labels?.[index] }}
            </template>
          </MapLegend>
        </div>

        <div v-if="data.network">
          <p class="mb-2">
            {{ t('Cycling network') }}
          </p>
          <MapLegend :class-breaks="data.network.classbreaks">
            <template
              v-if="data.network.labels"
              #label="{ index }"
            >
              {{ data.network.labels?.[index] }}
            </template>
          </MapLegend>
        </div>
      </div>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
