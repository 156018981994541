export type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
  Pick<T, Exclude<keyof T, Keys>>
  & {
    [K in Keys]-?:
      Required<Pick<T, K>>
      & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
  Pick<T, Exclude<keyof T, Keys>>
  & {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export enum Directions {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type Direction = `${Directions}`

export enum GeometriesTypes {
  H3_8 = 'h3_8',
  H3_9 = 'h3_9',
  H3_10 = 'h3_10',
  H3_11 = 'h3_11',
  ADMIN_08 = 'admin_08',
  ADMIN_09 = 'admin_09',
  ADMIN_10 = 'admin_10',
  ADMIN_11 = 'admin_11',
  ADMIN_12 = 'admin_12',
  IRIS = 'iris',
  // barri
  // basomrade
  // com2019
  // districte
  // granbarri
  // kulad_linnaosad
  // ortsteile
  // planungsraum
  // ratp
  // seccionado
  // secteur_stat
  // wohnquartiere
}

export type GeometryType = Lowercase<keyof typeof GeometriesTypes>

type CellsTypesExtract = Extract<GeometriesTypes, GeometriesTypes.H3_8 | GeometriesTypes.H3_9 | GeometriesTypes.H3_10 | GeometriesTypes.H3_11>
export type CellsType = `${CellsTypesExtract}`

export function isCellsType(type: GeometryType): type is CellsType {
  const types: GeometryType[] = Object.values(GeometriesTypes)
  return types.filter(t => t.startsWith('h3_')).includes(type)
}

export type ExpressionItem = number | string | boolean | ExpressionItem[]
export type Expression = ExpressionItem[]

export enum RegionsCodes {
  EU = 'EU',
  NA = 'NA',
  APAC = 'APAC',
}

export type RegionCode = `${RegionsCodes}`
