<script setup lang="ts">
import type { MetricOptions, SeriePercent } from '@/types/charts.types'

defineOptions({
  name: 'PercentBarMetric',
})

const props = withDefaults(defineProps<Props>(), {
  data: () => [],
  options: () => ({}),
  legend: true,
  limit: 5,
  minWidth: 10,
  height: 'h-4',
})

interface Props {
  data: SeriePercent[]
  options?: MetricOptions
  legend?: boolean
  limit?: number
  minWidth?: number
  height?: string // TailwindCSS height class
}

const { t } = useI18n()
const isOpen = ref(false)

const series = computed((): SeriePercent[] | null => {
  const { data, options } = props

  if (!data) {
    return null
  }

  return data.map(
    (d, dataIndex) => {
      const label = options.label ? options.label(d) : d.label

      return {
        ...d,
        name: label,
        value: d.value,
        percent: d.percent,
        color: (options.color && options.color(d)) || d.color || getColorByIndex(dataIndex),
      }
    },
  )
})

const limitedSeries = computed((): SeriePercent[] | null => {
  const { limit } = props
  const seriesRef = series.value

  if (!seriesRef) {
    return null
  }

  if (limit && seriesRef.length > limit) {
    const limited = seriesRef.slice(0, limit)

    limited?.push({
      name: t('Other'),
      value: seriesRef.slice(limit)
        .reduce((total, { value }) => total + value, 0) ?? 0,
      percent: seriesRef.slice(limit)
        .reduce((total, { percent }) => total + percent, 0) ?? 0,
      color: getColorByIndex(limit),
    })

    return limited || null
  } else {
    return seriesRef || null
  }
})
</script>

<template>
  <div class="percent-bar-metric">
    <div class="flex relative w-full">
      <div
        v-if="limitedSeries === null"
        class="w-full text-center text-gray-400 text-sm"
        :class="[height]"
      >
        {{ t('N/A') }}
      </div>
      <template v-else-if="limitedSeries?.length > 0">
        <DTooltip
          v-for="{ name, percent, value, color } in limitedSeries"
          :key="name"
          :class="[height]"
          :style="{
            backgroundColor: color,
            width: `${percent}%`,
            minWidth: percent > 0 ? `${minWidth}px` : null,
          }"
        >
          <div />
          <template #title>
            <div
              class="h-4 w-4 rounded-full inline-block align-middle mr-1"
              :style="{ backgroundColor: color as string }"
            />
            <strong>{{ name }}:</strong>
          </template>
          <template #content>
            <slot
              name="tooltip"
              :value="formatNumber(value)"
              :percent="formatPercent(percent)"
            >
              <p class="whitespace-nowrap">
                {{ `${formatNumber(value)} (${formatPercent(percent)})` }}
              </p>
            </slot>
          </template>
        </DTooltip>
      </template>

      <div
        v-else
        class="w-full bg-grey-100"
        :class="[height]"
      />
    </div>

    <div
      v-if="legend && series"
      class="pt-4"
    >
      <ChartLegend
        v-if="limitedSeries"
        class="flex-col"
        :series="limitedSeries"
        :dimension="options.dimension"
        :sub-legend="
          (d) => ('percent' in d ? ` - ${formatPercent(d.percent)}` : null)
        "
        :selectable="false"
      />
      <DLink
        v-if="limit && series.length > limit"
        class="text-blue-500 underline text-sm ml-6"
        @click="() => (isOpen = true)"
      >
        {{ t('See all') }}
      </DLink>

      <DModal
        v-if="isOpen"
        @close="() => (isOpen = false)"
      >
        <template #title>
          {{ t('Details on cars models') }}
        </template>

        <div class="max-h-60 overflow-auto">
          <ul
            v-for="item in series"
            :key="item.name"
          >
            <li>
              {{ item.name }}
              <span class="font-bold text-grey-400 float-right">{{
                formatPercent(item.percent)
              }}</span>
            </li>
          </ul>
        </div>
      </DModal>
    </div>
  </div>
</template>
