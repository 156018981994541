<script setup lang="ts">
import cityZonesQuery from '@/graphql/queries/cityZones.gql'

const route = useRoute()
const cityStore = useCityStore()

const {
  result: cityZones,
  loading,
  onError,
} = useQuery(
  cityZonesQuery,
  computed(() => ({
    city: route.params.citySlug,
  })),
)

onError((...args) => {
  console.error('error', args)
})

const { city, isLoading } = storeToRefs(cityStore)
</script>

<template>
  <MapContainer>
    <RouterView
      :crawls="cityZones?.city?.crawlZones"
      :geometries="cityZones?.city?.zones"
      :is-loading="loading || isLoading"
      :center="city?.center ? [city?.center.lng, city?.center.lat] : null"
      :zoom="city?.default_zoom"
    />
  </MapContainer>
</template>
