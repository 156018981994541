<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

defineOptions({
  name: 'CyclabilityOverviewBarcelona',
})

const { t } = useI18n()

const dateRange = {
  start: new Date(formatDate('2024-04-29', DATE_FORMAT)),
  end: new Date(formatDate('2024-06-29', DATE_FORMAT)),
}

const { translateDate } = useDateTranslation()

function translateRange(range: DateRange) {
  return {
    start: translateDate(new Date(range.start), DATES_FORMATS.FULL),
    end: translateDate(new Date(range.end), DATES_FORMATS.FULL),
  }
}
</script>

<template>
  <BoardSubTitle icon="list">
    <span class="capitalized">{{ t('dates.daterange_formated_human', translateRange(dateRange)) }}</span>
  </BoardSubTitle>

  <BoardGrid class="mt-4 lg:grid-cols-6">
    <CityCyclabilityPassagesMetric class="lg:col-span-3 xl:col-span-4" />
    <CityCyclabilityBikesPerformance class="lg:col-span-3 xl:col-span-2" />
    <CityCyclabilityPassagesHistoryMetric class="lg:col-span-6" />
    <CityCyclabilitySharedBikesPerfMetric class="lg:col-span-6" />
  </BoardGrid>

  <BoardSubTitle icon="list">
    {{ t('cyclability.additional_informations') }}
  </BoardSubTitle>

  <BoardGrid class="mt-4 lg:grid-cols-6">
    <CityCyclabilityCyclistAnalysisMetric class="lg:col-[1_/_span_3]" />
    <CityCyclabilityNetworkOverview class="lg:col-span-3 row-span-2" />
    <CityCyclabilityOthersAnalysisMetric class="lg:col-[1_/_span_3]" />
  </BoardGrid>
</template>
