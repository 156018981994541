<script setup>
import { REGIONS } from '@/utils/constants'

defineOptions({
  name: 'OverviewMap',
})

const props = defineProps({
  regionCode: {
    type: String,
    default: null,
  },
  cities: {
    type: Object,
    default: null,
  },
  availableVehicles: {
    type: Object,
    default: null,
  },
  loading: Boolean,
})

const emit = defineEmits(['update:regionCode'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { getCityNameLocalized } = useCitiesStore()

const { regionCode } = useVModels(props, emit)

const crisp = inject('crisp', null)

const region = computed(() => REGIONS.find(r => r.code === get(regionCode)))
const cities = computed(() => props.cities?.data)
const totalCitiesFromRegion = computed(
  () => get(cities).filter(c => c.region === get(regionCode)).length,
)

function onClickOnMap({ event }) {
  if (event.originalEvent?.target?.className?.includes('city-marker')) {
    return
  }

  router.push({
    name: 'Overview',
    params: { ...route.params },
  })
}

function onClickOnCity(city) {
  if (city.locked) {
    router.push({
      name: 'OverviewCity',
      params: { ...route.params, citySlug: city.name },
    })
  } else {
    router.push({
      name: 'City',
      params: { citySlug: city.name },
    })
  }
}

const [DefineTemplateLegend, ReuseTemplateLegend] = createReusableTemplate()
const [DefineTemplateInfo, ReuseTemplateInfo] = createReusableTemplate()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller('md')
</script>

<template>
  <MapLibre
    v-if="region"
    :center="region.center"
    :zoom="region.zoom"
    @click="onClickOnMap"
  >
    <!-- TODO :z-index-offset="city.name === route.params.citySlug ? 1000 : city.locked ? null : 100" -->

    <MapLibreMarker
      v-for="city in cities"
      :key="`${city.name}`"
      :lng-lat="[city.center?.lng, city.center?.lat]"
      @click="
        marker => {
          marker.togglePopup()
          onClickOnCity(city)
        }
      "
      @mouseenter="marker => marker.togglePopup()"
      @mouseleave="marker => marker.togglePopup()"
    >
      <template #element>
        <div
          class="city-marker h-4 w-4 rounded-full shadow-marker ring-[3px] ring-inset ring-white hover:cursor-pointer"
          :class="{
            'bg-blue-500': !city.locked,
            'bg-[#b7ba42]': city.locked,
            'shadow-marker-selected': city.name === route.params.citySlug,
          }"
        />
      </template>
      <MapLibrePopup
        anchor="left"
        :offset="10"
        close-on-click
      >
        <template #element>
          <div class="flex items-center justify-center space-x-4">
            <h4 class="gradient-text font-title font-bold">
              {{ getCityNameLocalized(city.name) }}<br>
              <span class="!text-xs font-normal text-grey-300">
                {{ city.providersCount }}
                {{ t('services', { count: city.providersCount }) }}
              </span>
            </h4>
            <div class="space-x-2 whitespace-nowrap">
              <VehicleIcon
                v-if="city.vehicleTypes?.includes('BIKE')"
                type="bike"
                size="sm"
              />
              <VehicleIcon
                v-if="city.vehicleTypes?.includes('SCOOTER')"
                type="scooter"
                size="sm"
              />
              <VehicleIcon
                v-if="city.vehicleTypes?.includes('MOTORSCOOTER')"
                type="motorscooter"
                size="sm"
              />
              <VehicleIcon
                v-if="city.vehicleTypes?.includes('CAR')"
                type="car"
                size="sm"
              />
            </div>
          </div>
        </template>
      </MapLibrePopup>
    </MapLibreMarker>

    <slot />

    <DefineTemplateLegend>
      <DLoader v-if="loading" />
      <p>
        <img
          src="@/assets/marker-blue@2x.png"
          :alt="t('maps_markers.blue')"
          class="inline h-6 w-6"
        >
        &nbsp;{{ t('Your cities') }}
      </p>
      <p>
        <img
          src="@/assets/marker-yellow@2x.png"
          :alt="t('maps_markers.yellow')"
          class="inline h-6 w-6"
        >
        &nbsp;{{ t('Upgrade to access') }}
      </p>
    </DefineTemplateLegend>

    <DefineTemplateInfo>
      <div class="m-4 pb-4 border-b border-grey-100">
        <h4 class="font-title text-xs font-normal uppercase text-grey-400">
          {{ t('At a glance') }}
        </h4>
        <p>
          <DSelectPicker
            v-model="regionCode"
            :options="
              REGIONS.map(region => ({
                text: t(region.name),
                value: region.code,
              }))
            "
            class="font-title text-2xl font-bold text-blue-500 hover:underline inline-block"
            no-style
          />
        </p>

        <PlaceHolder v-if="loading">
          <PlaceHolderLine :lines="1" />
        </PlaceHolder>

        <template v-else>
          <p class="font-title text-base font-normal text-blue-500">
            {{
              t(
                'Data from {n} cities',
                { n: totalCitiesFromRegion },
                totalCitiesFromRegion,
              )
            }}
          </p>
        </template>

        <p class="mt-4 text-base">
          {{ t('AVG Last 30 days vs Previous period') }}
        </p>
      </div>

      <PlaceHolder v-if="loading">
        <PlaceHolderLine />
      </PlaceHolder>
      <DTable
        v-else
        no-border
      >
        <thead>
          <tr class="!border-0 text-2xs">
            <th class="w-1/3">
              {{ t('mode') }}
            </th>
            <th class="w-1/3 text-right">
              {{ t('vehicles') }}
            </th>
            <th class="w-1/3" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in sortVehicleTypes(
              filterVehicleTypes(
                vehicleTypesDataToArray(availableVehicles.data),
              ),
            ).filter(t => t.value)"
            :key="data.type"
          >
            <td class="text-left">
              <VehicleIcon
                :type="data.type"
                size="sm"
              />
            </td>
            <td class="text-right text-base font-bold text-grey-700">
              {{ formatNumber(data.value) }}
            </td>
            <td class="text-right">
              <Trend
                v-if="data.trend"
                :trend="data.trend.percent"
              />
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </DTable>
    </DefineTemplateInfo>

    <MapLibreControl
      v-if="isMobile"
      position="bottom-right"
      no-style
      class="flex gap-4 p-4"
    >
      <MobileActionButton
        icon="information"
        class="relative z-underall"
      >
        <ReuseTemplateInfo />
      </MobileActionButton>
      <MobileActionButton
        icon="legend"
        class="relative z-underall"
      >
        <ReuseTemplateLegend />
      </MobileActionButton>
    </MapLibreControl>

    <MapLibreControl
      v-if="!isMobile"
      :class="{
        'md:!mr-24': crisp?.injected,
      }"
      position="bottom-right"
    >
      <ReuseTemplateLegend />
    </MapLibreControl>

    <MapLibreControl
      v-if="!isMobile"
      position="bottom-left"
    >
      <ReuseTemplateInfo />
    </MapLibreControl>

    <MapLibreControl
      :position="isMobile ? 'bottom-left' : 'top-right'"
      no-style
      :class="{ 'p-4': !isMobile, 'py-6 px-4': isMobile }"
    >
      <FreemiumUpgradeBtn />
    </MapLibreControl>
  </MapLibre>
</template>
