import type { Network } from '@/composables/cyclability'
import { decodeNetwork, fetchGzip } from '@cd/screen-data'
import parquetjs from '@dsnp/parquetjs'

export async function fetchParquet(url: string, forceGzip: boolean = true): Promise<any[]> {
  let buffer

  if (forceGzip) {
    buffer = await fetchGzip(url)
  } else {
    try {
      buffer = await fetch(url).then(response => response.arrayBuffer())
    } catch (e) {
      console.error(e)
    }
    // buffer = await fetch(url).then(response => response.body)
  }
  // @ts-expect-error because the type definition is wrong
  const reader = await parquetjs.ParquetReader.openBuffer(buffer)

  const cursor = reader.getCursor()

  const res = []

  let record = await cursor.next()
  while (record) {
    res.push(record)
    record = await cursor.next()
  }

  await reader.close()

  return res
}

export async function fetchNetwork(url: string): Promise<Network | undefined> {
  try {
    const response = await fetchGzip(url)
    return decodeNetwork(response) as Network
  } catch (e) {
    console.error(e)
  }
}
