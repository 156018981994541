<script setup lang="ts">
defineOptions({
  name: 'CityCyclabilityCyclistAnalysisMetric',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())

const { writeCache } = useWriteCacheForMetric('cityCyclabilityCyclistAnalysis')

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    gender: [
      {
        value: 18,
        percent: 18,
        label: 'Woman',
        color: '#adbff3',
      },
      {
        value: 82,
        percent: 82,
        label: 'Man',
        color: '#32498a',
      },
    ],
    age: [
      {
        value: 20,
        percent: 20,
        label: '16-29',
        color: '#98aff0',
      },
      {
        value: 72,
        percent: 72,
        label: '30-64',
        color: '#7695eb',
      },
      {
        value: 8,
        percent: 8,
        label: '65+',
        color: '#547ae6',
      },
    ],
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityCyclistAnalysis"
    :used-filters="[]"
  >
    <template #title>
      {{ t('cyclability.cyclist_analysis.title') }}
    </template>

    <template #subtitle>
      {{ t('2022') }}
    </template>

    <template #default="{ data }">
      <div class="flex gap-4">
        <PercentBarMetric
          class="flex-1"
          :data="data.gender"
          :label="t('cyclability.cyclist_analysis.gender')"
        />

        <PercentBarMetric
          class="flex-1"
          :data="data.age"
          :label="t('cyclability.cyclist_analysis.age')"
        />
      </div>
    </template>
  </MetricCard>
</template>
