<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { RoadCycleRapMetric, RoadMetric } from '@/composables/cyclability'

interface CitySegmentsProps {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  metric?: RoadMetric
  selected?: string[]
  classBreaks: ClassBreaks
  labels?: string[]
  layerId?: string
}

defineOptions({
  name: 'CitySegments',
})

const props = withDefaults(defineProps<CitySegmentsProps>(), {
  geojson: () => newFeatureCollection(),
  metric: 'score',
  selected: () => [],
  layerId: 'city-segments',
})

const emit = defineEmits(['ready'])

const { geojson, metric, classBreaks } = toRefs(props)
const colorBreaks = useExpressionColorFromBreaks(classBreaks, metric)

const paintExpression = computed(() => {
  return {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      PALETTE_COLORS[0],
      colorBreaks.value,
    ],
    'line-width': 12,
  }
})
</script>

<template>
  <MapLibrePopup
    :offset="[0, -5]"
    :layer-id="layerId"
  >
    <template #element="{ feature }">
      <div class="flex w-28 flex-col whitespace-normal text-xs">
        <p v-if="cycleRapMetrics.includes(metric as RoadCycleRapMetric) || metric === 'pothole'">
          {{ labels?.[feature?.properties?.[metric]] }}
        </p>
        <p v-else>
          {{ feature?.properties?.[metric] }}
        </p>
      </div>
    </template>
  </MapLibrePopup>

  <MapLibreSourceGeojson
    :id="`${layerId}-border`"
    :layer-props="{
      type: 'line',
      paint: {
        'line-color': 'black',
        'line-opacity': 0.66,
        'line-width': 16,
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
    }"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
  />

  <MapLibreSourceGeojson
    :id="layerId"
    :before-id="`${layerId}-border`"
    :layer-props="{
      type: 'line',
      paint: paintExpression,
      layout: {
        'line-cap': 'round', // round | butt | square
        'line-join': 'miter', // round | bevel | miter
      },
    }"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />
</template>
