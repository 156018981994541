<script setup>
const props = defineProps({
  rounded: Boolean,
  centered: Boolean,
  animated: {
    type: Boolean,
    default: true,
  },
})

provide('parent-props', props)
</script>

<template>
  <div class="placeholders">
    <slot />
  </div>
</template>

<style lang="pcss">
/* Animations */
@keyframes placeholders-animation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.placeholders {
  .placeholders-animated::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 1000px;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #eeeeee80 15%, transparent 30%);

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholders-animation;
    animation-timing-function: linear;
  }
}
</style>
