<script setup>
import DIconProps from '../DIcon/DIconProps.js'

const props = defineProps({
  ...DIconProps,
  variant: {
    type: String,
    default: null,
    validator: validatorButtonsVariants,
  },
  disabled: Boolean,
  active: Boolean,
  light: Boolean,
  size: {
    type: String,
    default: 'md',
    validator: validatorSizes,
  },
  iconSize: {
    type: String,
    default: props => props.size,
    validator: validatorSizes,
  },
})
</script>

<template>
  <button
    type="button"
    class="d-icon-button flex-center inline-flex overflow-hidden rounded-full text-center hover:bg-black/20"
    :class="[
      variant,
      {
        disabled,
        active,
        'h-8 w-8': size === 'sm',
        'h-10 w-10': size === 'md',
        'h-12 w-12': size === 'lg',
      },
    ]"
  >
    <DIcon
      v-bind="props"
      class="flex-1"
      :class="{
        'text-white': light,
      }"
      :size="iconSize"
    />
  </button>
</template>

<style lang="pcss">
.d-icon-button {
  &.success {
    @apply bg-green-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-green-700;
    }
    &.disabled {
      @apply bg-green-300;
    }
  }

  &.alert {
    @apply bg-red-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-red-700;
    }
    &.disabled {
      @apply bg-red-300;
    }
  }

  &.warning {
    @apply bg-amber-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-amber-700;
    }
    &.disabled {
      @apply bg-amber-300;
    }
  }

  &.fill-primary {
    @apply bg-blue-500 text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-blue-700;
    }
    &.disabled {
      @apply bg-blue-300;
    }
  }
  &.fill-secondary {
    @apply bg-white text-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply bg-grey-100 text-blue-700;
    }
    &.disabled {
      @apply bg-white text-blue-300;
    }
  }

  &.gradient-primary {
    @apply text-white bg-gradient-to-r from-teal-500 to-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply from-teal-700 to-blue-700;
    }
    &.disabled {
      @apply from-teal-300 to-blue-300;
    }
  }
  &.gradient-secondary {
    @apply bg-white;

    & * {
      @apply bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent;
    }

    &.active,
    &:not(.disabled):hover {
      @apply bg-grey-100;

      & * {
        @apply from-teal-700 to-blue-700;
      }
    }
    &.disabled * {
      @apply from-teal-300 to-blue-300;
    }
  }

  &[class*='outline-'] {
    @apply border-2 bg-transparent;

    &.sm {
      @apply border;
    }
  }

  &.outline-primary {
    @apply border-blue-500 text-blue-500;

    &.active,
    &:not(.disabled):hover {
      @apply bg-blue-500 text-white;
    }
    &.disabled {
      @apply border-blue-300 text-blue-300;
    }
  }
  &.outline-secondary {
    @apply border-white text-white;

    &.active,
    &:not(.disabled):hover {
      @apply bg-white text-blue-500;
    }
    &.disabled {
      @apply border-white/50 text-white/50;
    }
  }

  &.disabled {
    @apply cursor-not-allowed text-white/50;
  }
}
</style>
