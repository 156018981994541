import type { RouteLocation } from 'vue-router'
import { StorageSerializers } from '@vueuse/core'
import { defineStore } from 'pinia'

type ProviderOAuth = 'google' | 'microsoft' | 'linkedin' | 'github'

export const useLoginModalsStore = defineStore('auth', () => {
  const redirect = useStorage<RouteLocation | null>(
    'redirect',
    null,
    localStorage,
    {
      serializer: StorageSerializers.object,
    },
  )
  const currentModal = ref<'signin' | 'oauth' | 'forgot-password' | 'change-organization' | null>() //
  const oAuthProvider = ref<ProviderOAuth | null>()
  const forgotEmail = ref<string | null>()

  function openSignin() {
    const userStore = useUserStore()

    if (userStore.user && !userStore.organization) {
      // user is already connected and has no organization
      return openChangeOrganization()
    }

    currentModal.value = 'signin'
  }
  function openOAuth(provider: ProviderOAuth) {
    currentModal.value = 'oauth'
    oAuthProvider.value = provider
  }
  function openForgotPassword(email = null) {
    currentModal.value = 'forgot-password'
    forgotEmail.value = email
  }
  function openChangeOrganization() {
    currentModal.value = 'change-organization'
  }
  function closeModal() {
    currentModal.value = null
  }

  return {
    redirect,
    currentModal,
    oAuthProvider,
    forgotEmail,

    openSignin,
    openOAuth,
    openForgotPassword,
    openChangeOrganization,
    closeModal,
  }
})

// make sure to pass the right store definition, `useLoginModalsStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoginModalsStore, import.meta.hot))
}
