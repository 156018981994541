import type { MaybeEstimatedSerie } from '@/types/charts.types'
import type { MaybeRef } from 'vue'

export function useChartSeriesVisible(series: MaybeRef<MaybeEstimatedSerie[]>) {
  if (!isRef<MaybeEstimatedSerie[]>(series)) {
    series = ref(series)
  }

  const seriesVisible = ref<string[]>([])

  onMounted(() => {
    resetSeriesVisible()
  })

  watch(series, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      resetSeriesVisible()
    }
  })

  function resetSeriesVisible() {
    seriesVisible.value
      = unref(series).map((dataset) => {
        return dataset.name || ''
      }) || []
  }

  return {
    seriesVisible,
    resetSeriesVisible,
  }
}
