<script setup>
import updateOrganizationMutation from '@/graphql/mutations/updateOrganization.gql'
import updateUserMutation from '@/graphql/mutations/updateUser.gql'
import * as vat from '@fluctuo/jsvat'
import useVuelidate from '@vuelidate/core'

import { required, requiredIf } from '@vuelidate/validators'
import { allCountries } from 'country-region-data'

const props = defineProps({
  withUserData: Boolean,
})

const emit = defineEmits(['updated', 'failed'])

const { t } = useI18n()
const { loadUser } = useUserStore()
const { user, organization } = storeToRefs(useUserStore())

const { mutate: updateOrganization } = useMutation(updateOrganizationMutation, {
  clientId: CAPTAIN,
})
const { mutate: updateUser } = useMutation(updateUserMutation, {
  clientId: CAPTAIN,
})

const inputs = reactive({
  userName: props.withUserData && user.value.name,
  company: organization.value.company || false,
  organizationName: organization.value.name,
  address1: organization.value.address1,
  address2: organization.value.address2,
  zipCode: organization.value.zipCode,
  city: organization.value.city,
  country: organization.value.country,
  state: organization.value.state,
  vatNumber: organization.value.vatNumber,
})
const saving = ref(false)
const updateError = ref(false)

const rules = computed(() => ({
  userName: {
    required,
  },
  organizationName: {
    required,
  },
  address1: {
    required: requiredIf(() => inputs.company),
  },
  zipCode: {
    required: requiredIf(() => inputs.company),
  },
  city: {
    required: requiredIf(() => inputs.company),
  },
  country: {
    required: requiredIf(() => inputs.company),
  },
  state: {
    required: requiredIf(() => inputs.company),
  },
  vatNumber: {
    required: requiredIf(
      () => inputs.company && EUROPEAN_COUNTRIES.includes(inputs.country),
    ),
    validVATNumber() {
      if (inputs.country && inputs.vatNumber) {
        const country = vat.countries.find(c =>
          c.codes.includes(inputs.country || 'FR'),
        )

        if (!country) {
          return true
        }

        const checkedVAT = vat.checkVAT(inputs.vatNumber, [country])

        return checkedVAT.isValid
      } else {
        return true
      }
    },
  },
}))

const validation = useVuelidate(rules, inputs)

const regions = computed(() => {
  return inputs.country
    ? allCountries.find(
      ([, countryShortCode]) => countryShortCode === inputs.country,
    )[2]
    : []
})

const VATExample = computed(() => {
  const c = vat.countries.find(c => c.codes.includes(inputs.country || 'FR'))

  return c && `${t('e.g')} ${vat.getVATExample(c)}`
})

async function onSubmitOrganizationForm(e) {
  e.preventDefault()
  saving.value = true
  updateError.value = null
  validation.value.$touch()

  if (validation.value.$invalid) {
    saving.value = false

    return
  }

  try {
    if (props.withUserData) {
      if (LOGIN_DISABLED) {
        user.name = inputs.userName
      } else {
        await updateUser({
          name: inputs.userName,
        })
      }
    }

    if (!LOGIN_DISABLED) {
      await updateOrganization({
        id: organization.value.id,
        name: inputs.organizationName,
        company: inputs.company,
        address1: inputs.address1,
        address2: inputs.address2,
        zipCode: inputs.zipCode,
        city: inputs.city,
        country: inputs.country,
        state: inputs.state,
        vatNumber: inputs.vatNumber,
      })
    }

    await loadUser()

    saving.value = false
    emit('updated')
  } catch (error) {
    saving.value = false
    updateError.value = error.message
  }
}

function isDisabled(value) {
  return Boolean(organization.value[value])
}

function validateState(name) {
  const { $dirty, $error } = validation.value[name]
  return $dirty ? !$error : null
}
</script>

<template>
  <form
    class="pt-3"
    @submit.prevent="onSubmitOrganizationForm"
  >
    <DField v-if="withUserData">
      <template #label>
        {{ t('Name (first & last)') }}
      </template>
      <DInputText
        v-model="inputs.userName"
        :placeholder="t('Enter full name')"
        :state="validateState('userName')"
      />
      <p
        v-if="validateState('userName') === false"
        class="text-sm text-red-500"
      >
        {{ t('Please enter your full name.') }}
      </p>
    </DField>

    <DInputSwitch
      v-model="inputs.company"
      size="sm"
      :disabled="isDisabled('company')"
      class="mb-2"
    >
      {{ t('Company ?') }}
    </DInputSwitch>

    <DField>
      <template #label>
        {{ t('Organization') }}
      </template>
      <DInputText
        v-model="inputs.organizationName"
        :placeholder="t('Enter full name')"
        :state="validateState('organizationName')"
      />
      <p
        v-if="validateState('organizationName') === false"
        class="text-sm text-red-500"
      >
        {{ t('Please enter your full name.') }}
      </p>
    </DField>

    <DField>
      <template #label>
        {{ t('Address line 1') }}
      </template>
      <DInputText
        v-model="inputs.address1"
        :placeholder="t('Address')"
        :state="validateState('address1')"
      />
      <p
        v-if="validateState('address1') === false"
        class="text-sm text-red-500"
      >
        {{ t('Please enter valid address') }}
      </p>
    </DField>

    <DField>
      <template #label>
        {{ t('Address line 2') }}
      </template>
      <DInputText
        v-model="inputs.address2"
        :placeholder="t('Address line 2')"
      />
    </DField>

    <div class="flex flex-col md:flex-row md:space-x-4">
      <DField class="flex-1">
        <template #label>
          {{ t('zipCode') }}
        </template>
        <DInputText
          v-model="inputs.zipCode"
          :placeholder="t('Postal code')"
          :state="validateState('zipCode')"
        />
        <p
          v-if="validateState('zipCode') === false"
          class="text-sm text-red-500"
        >
          {{ t('Please enter a valid postal code') }}
        </p>
      </DField>

      <DField class="flex-1">
        <template #label>
          {{ t('City') }}
        </template>
        <DInputText
          v-model="inputs.city"
          :placeholder="t('City')"
          :state="validateState('city')"
        />
        <p
          v-if="validateState('city') === false"
          class="text-sm text-red-500"
        >
          {{ t('Please enter a valid city') }}
        </p>
      </DField>
    </div>

    <div class="flex flex-col md:flex-row md:space-x-4">
      <DField class="flex-1">
        <template #label>
          {{ t('Country') }}
        </template>
        <DSelect
          v-model="inputs.country"
          :options="
            allCountries.map(([name, shortCode]) => ({
              value: shortCode,
              text: name,
            }))
          "
          :state="validateState('country')"
          @change="inputs.state = null"
        />
        <p
          v-if="validateState('country') === false"
          class="text-sm text-red-500"
        >
          {{ t('Please select a country') }}
        </p>
      </DField>

      <DField class="flex-1">
        <template #label>
          {{ t('State') }}
        </template>
        <DSelect
          v-model="inputs.state"
          :options="
            regions.map(([name, shortCode]) => ({
              value: shortCode,
              text: name,
            }))
          "
          :state="validateState('state')"
        />
        <p
          v-if="validateState('state') === false"
          class="text-sm text-red-500"
        >
          {{ t('Please select a state') }}
        </p>
      </DField>
    </div>

    <DField v-if="inputs.company">
      <template #label>
        {{ t('VAT Number (mandatory for companies registered in the EU)') }}
      </template>
      <DInputText
        v-model="inputs.vatNumber"
        :placeholder="VATExample"
        :disabled="isDisabled('vatNumber')"
        :state="validateState('vatNumber')"
      />
    </DField>

    <DAlert
      v-if="updateError"
      class="mt-2 mb-2"
      variant="alert"
      closable
    >
      {{ updateError }}
    </DAlert>

    <DButton
      type="submit"
      :disabled="saving"
      :loading="saving"
    >
      {{ t('Save changes') }}
    </DButton>
  </form>
</template>
