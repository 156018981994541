<script setup lang="ts">
import type { BarMetricOptions, MaybeEstimatedSerie, MetricChart } from '@/types/charts.types'

defineOptions({
  name: 'BarMetric',
})

const props = withDefaults(defineProps<BarMetricProps>(), {
  type: 'category',
  options: () => ({}),
  legend: true,
})

type BarMetricProps = MetricChart<BarMetricOptions>

const filtersStore = useFiltersStore()
const { labels } = useCompareLabels()

const series = computed<MaybeEstimatedSerie[]>(() => {
  let colorIndex = 0
  const { options, data } = props

  return (
    data.series.map((d, dataIndex) => {
      const dataset = {
        ...d,
        dataIndex,
        category: data.labels?.[dataIndex],
        label: options.label ? options.label(d, dataIndex) : d.name,
        color:
          (options.color && options.color(d))
          || getColorFromSerie(d, colorIndex, options.colors),
      }

      if (d.unlocked !== false) {
        colorIndex++
      }

      return dataset
    }) || []
  )
})

const { seriesVisible, resetSeriesVisible } = useChartSeriesVisible(series)

const chartData = shallowRef()
const chartOptions = shallowRef()

watch(
  [
    series,
    seriesVisible,
    labels,
    () => props.options,
    () => props.data,
    () => props.type,
    () => filtersStore.timeMode,
    () => filtersStore.comparativeRange,
  ],
  ([
    series,
    seriesVisible,
    labels,
    options,
    data,
    type,
    timeMode,
    comparativeRange,
  ]) => {
    const timeUnit = options.timeMode || timeMode
    const isComparative = Boolean(options.isCompare && comparativeRange)
    const isStacked = !(options.unstacked === true) && !isComparative

    if (!data) {
      set(chartData, {})
    } else {
      set(chartData, {
        labels: data.labels || data.categories || [],
        datasets:
          series?.map((d: MaybeEstimatedSerie) => {
            return {
              label: d.label,
              backgroundColor: d.color,
              data: seriesVisible.includes(d.name || '')
                ? d.data.map(d =>
                  Array.isArray(d)
                    ? { x: d[0], y: d[1], estimated: d[2] }
                    : Number(d),
                )
                : [],
              status: d.status,
            }
          }) || [],
      })
    }

    const scaleXforTime = isComparative
      ? {
          labels,
        }
      : {
          type: 'time',
          time: {
            tooltipFormat: getTooltipFormatOfDate(timeUnit),
            isoWeekday: true,
            unit: timeUnit,
          },
        }

    const scaleXforCategory = {
      type: 'category',
    }

    set(chartOptions, {
      ...options,
      scales: {
        ...options.scales,
        y: {
          beginAtZero: true,
          min: 0,
          stacked: isStacked,
          ...options.scales?.y,
        },
        x: {
          grid: { display: false },
          stacked: isStacked,
          offset: !isStacked,
          ...(type === 'time' ? scaleXforTime : scaleXforCategory),
          ...options.scales?.x,
        },
      },
    })
  },
  { immediate: true, deep: true },
)
</script>

<template>
  <div class="flex flex-col">
    <div class="flex-1 overflow-auto">
      <BarChart
        v-if="chartData && chartOptions"
        :data="chartData"
        :options="chartOptions"
      />
    </div>
    <div
      v-if="legend"
      class="p-3"
    >
      <ChartLegend
        v-model="seriesVisible"
        :dimension="options.dimension"
        :series="series"
        sort-by="label"
        :palette="chartOptions?.colors"
        @reset="resetSeriesVisible"
      />
    </div>
  </div>
</template>
