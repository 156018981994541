import type { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client'

type Gql = '' | DocumentNode | TypedDocumentNode

const queries: [string, Gql][] = Object.entries(
  import.meta.glob('@/graphql/queries/*.gql', {
    eager: true,
    import: 'default',
  }),
)

export function useWriteCacheForMetric(kpi: string | Ref<string>) {
  const { resolveClient } = useApolloClient()
  const client = resolveClient('default')

  const gql = computed((): Gql | undefined => {
    const query
      = get(kpi)
      && queries.find(([path]) => path.includes(`/${get(kpi)}.gql`))

    return query && query[1]
  })

  const { variables: globalVariables } = useMetricData(
    kpi,
    {},
    {
      queryOptions: {
        enabled: false,
        fetchPolicy: 'cache-only',
      },
    },
  )

  return {
    writeCache(variables: OperationVariables, data: any) {
      if (!gql.value) {
        return
      }

      client.writeQuery({
        query: gql.value,
        variables: {
          ...get(globalVariables),
          ...get(variables),
        },
        data: {
          [get(kpi)]: {
            metric: get(kpi),
            help: null,
            data,
          },
        },
      })
    },
  }
}

export function useWriteCache(queryName: string) {
  const { resolveClient } = useApolloClient()
  const client = resolveClient('default')

  const gql = computed(() => {
    const query
      = get(queryName)
      && queries.find(([path]) => path.includes(`/${get(queryName)}.gql`))

    return query && query[1]
  })

  return {
    writeCache(variables: OperationVariables, data: any) {
      if (!gql.value) {
        return
      }

      client.writeQuery({
        query: gql.value,
        variables,
        data: {
          [get(queryName)]: {
            ...data,
          },
        },
      })
    },
  }
}
