import { ENUM_LOCATION_TYPES } from '@/utils/constants'

export function useCityProviderFilters() {
  const { getPref, setPref } = usePreferences()

  const sortBy = ref(DEFAULT_SORT_BY)
  const vehicleTypesSelected = ref(
    getPref('boards.global.vehicleTypes') || VEHICLE_TYPES,
  )
  const locationsSelected = ref(getPref('boards.global.location') || [])
  const showLocked = ref(true)

  watchDeep(vehicleTypesSelected, (value) => {
    setPref('boards.global.vehicleTypes', value)
  })

  watchDeep(locationsSelected, (value) => {
    setPref('boards.global.location', value)
  })

  function filterAndSort(list) {
    const sortingBy = sortByAttr(get(sortBy))

    return list
      .filter((item) => {
        if (
          !get(showLocked)
          && (!item.unlocked
            || item.availableInCD === false
            || item.isMonitored === false)
        ) {
          return false
        }

        return true
      })
      .sort((a, b) => {
        return a.availableInCD === false || a.isMonitored === false
          ? 1
          : b.availableInCD === false || b.isMonitored === false
            ? -1
            : a.unlocked === false
              ? 1
              : b.unlocked === false
                ? -1
                : sortingBy(a, b)
      })
  }

  function resetFilters() {
    set(vehicleTypesSelected, VEHICLE_TYPES)
    set(locationsSelected, [])
  }

  function getVariableFromFilter(filterValue, type = ENUM_LOCATION_TYPES.city) {
    const array = filterValue
      .filter(location => location.type === type)
      .map(location => location.name)

    if (array.length) {
      return array
    }
  }

  return {
    sortBy,
    vehicleTypesSelected,
    locationsSelected,
    showLocked,
    filterAndSort,
    resetFilters,
    getVariableFromFilter,
  }
}
