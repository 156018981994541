<script setup lang="ts">
import type { DSelectPickerOption } from '@/components/ui/DSelectPicker/DSelectPicker.vue'
import type { TimeMode } from '@/types/charts.types'

const { t } = useI18n()
const { translateFromData } = useLabelTranslation()
const filtersStore = useFiltersStore()

const { setPref } = usePreferences()
watch(
  () => filtersStore.timeMode,
  (value) => {
    setPref('boards.global.timeMode', value)
  },
)

const TIME_MODES: TimeMode[] = ['day', 'week', 'month']

const options = computed((): DSelectPickerOption[] => {
  return TIME_MODES.map(mode => ({
    label: translateFromData('timeMode', mode),
    value: mode,
  }))
})
</script>

<template>
  <DSelectPicker
    id="timeMode"
    v-model="filtersStore.timeMode"
    :label="t('Frequency')"
    icon="data-settings"
    :options="options"
  />
</template>
