import { resolveValue } from 'path-value'

export function sortByAttr(attr: string, decorator = (v: any) => v): (a: any, b: any) => number {
  return (a, b) => {
    let aVal
    let bVal = null
    try {
      aVal = resolveValue(a, attr) || ''
    } catch {
      aVal = ''
    }

    try {
      bVal = resolveValue(b, attr) || ''
    } catch {
      bVal = ''
    }

    return Number.isNaN(Number(aVal)) || Number.isNaN(Number(bVal))
      ? decorator(aVal).localeCompare(decorator(bVal))
      : decorator(bVal ?? -1) - decorator(aVal ?? -1)
  }
}

export function quickSort(arr: number[]): number[] {
  if (arr.length <= 1) {
    return arr
  }

  const pivot = arr[Math.floor(arr.length / 2)]
  const left = []
  const right = []

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] < pivot) {
      left.push(arr[i])
    } else if (arr[i] > pivot) {
      right.push(arr[i])
    }
  }

  return [...quickSort(left), pivot, ...quickSort(right)]
}

export default {
  sortByAttr,
  quickSort,
}
