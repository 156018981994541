<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { NetorkLineID, Network, NetworkData, RoadLines } from '@/composables/cyclability'
import { ROAD_TYPES } from '@cd/screen-data'

interface NetwotkProperties {
  infra_type_key: number
}

type RoadFeatures = GeoJSON.Feature<GeoJSON.LineString, NetwotkProperties>

defineOptions({
  name: 'CityNetwork',
})

const props = withDefaults(defineProps<{
  layerId?: string
  network: Network
  data?: NetworkData
  classBreaks: ClassBreaks
  labels?: string[]
  metric?: string
  isLoading?: boolean
}>(), {
  layerId: 'city-network',
  metric: 'road',
})

const emit = defineEmits(['ready'])

const types = ROAD_TYPES
const { classBreaks } = toRefs(props)
const geojson = shallowRef<GeoJSON.FeatureCollection<GeoJSON.LineString, NetwotkProperties> | null>(null)
const paintColor = useExpressionColorFromBreaks(classBreaks, computed(() => props.metric === 'road' ? 'infra_type_key' : 'mean'))

function makeGeojson(network: Network, data?: NetworkData) {
  geojson.value = newFeatureCollection<GeoJSON.LineString, NetwotkProperties>(
    network.reduce<RoadFeatures[]>((acc, networkType: RoadLines, index) => {
      if (!networkType.lines || !networkType.lines.length) {
        return acc
      }

      const features = networkType.lines.map((line, lineIndex) => {
        const lineId = `${index}_${lineIndex}` as NetorkLineID

        return {
          type: 'Feature',
          properties: {
            infra_type_key: types.indexOf(networkType.roadType),
            mean: data?.get(lineId) || null,
          },
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
        } as RoadFeatures
      })

      return [...acc, ...features]
    }, []),
  )
}

watch([() => props.network, () => props.data], ([network, data]) => {
  makeGeojson(network, data)
}, { immediate: true })
</script>

<template>
  <MapLibreSourceGeojson
    v-if="geojson"
    :id="layerId"
    :layer-props="{
      type: 'line',
      paint: {
        'line-width': 2,
        'line-opacity': 0.73,
        'line-color': paintColor || '#576067',
      },
    }"
    :data="geojson"
    @ready="() => emit('ready')"
  />
</template>
