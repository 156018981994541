<script setup lang="ts">
import type { CyclabilityData, RoadMetric } from '@/composables/cyclability'
import type { GeometryType } from '@/types/index.types'
import type { LngLatLike } from 'maplibre-gl'

defineOptions({
  name: 'CyclabilityMapSegments',
})

withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['onZoom'])

interface CyclabilityMapSegmentsProps {
  zoom?: number
  center?: LngLatLike
  data: CyclabilityData
  geometryType?: GeometryType | null
  pathsMetric?: RoadMetric
  legendTitle?: string
  isLoading?: boolean
}

const { selectedArea } = useSelectedAreaFromUrl()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('onZoom', zoom)"
  >
    <MapLibreLayerKeepOrder>
      <template #default="scoped">
        <CitySegments
          v-if="data.roads"
          layer-id="city-segments"
          :geojson="data.roads.geojson"
          :metric="pathsMetric"
          :class-breaks="data.roads.classbreaks"
          :labels="data.roads.labels"
          :selected="selectedArea"
          @ready="() => scoped.reorder()"
        />

        <CityNetwork
          v-if="data.network"
          layer-id="city-network"
          :network="data.network.roads"
          :data="data.network.data"
          :class-breaks="data.network.classbreaks"
          :metric="data.network.metric"
          :is-loading="isLoading"
          @ready="() => scoped.reorder()"
        />

        <CellsGrid
          v-if="data.areas"
          layer-id="cells-grid"
          :data="data.areas.data"
          :class-breaks="data.areas.classbreaks"
          :cells-type="data.areas.type"
          @ready="() => scoped.reorder()"
        />
      </template>
    </MapLibreLayerKeepOrder>

    <MapSelectArea
      :layers-ids="['city-segments']"
      :show-button="false"
    />

    <CyclabilityMapLegends :data="data" />
  </MapLibre>

  <CyclabilitySidebarSegment :data="data" />
</template>
