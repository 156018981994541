<script setup lang="ts">
import type { Map } from 'maplibre-gl'

defineOptions({
  name: 'MapLibreLayerKeepOrder',
})

defineSlots<{
  default: (props: { reorder: () => void }) => void
}>()

const map = inject<Ref<Map | undefined>>('map', ref())
const slots = useSlots()

const layers = computed(() => {
  return slots.default?.()
    .filter((vnode: VNode) => vnode.type !== Comment && vnode.type !== Text)
    .map((vnode: VNode) => ({
      name: (vnode.type as Component)?.name,
      layerId: vnode.props?.layerId || vnode.props?.['layer-id'],
      props: 'props' in vnode ? vnode.props : null,
      vnode,
    }))
})

async function moveLayers() {
  await nextTick()

  const mapRef = map.value
  const layersRef = layers.value

  if (mapRef && layersRef) {
    layersRef.forEach((layer, index) => {
      if (index === 0) {
        return
      }

      const prevLayer = layersRef[index - 1]

      if (
        !prevLayer
        || !mapRef.getLayer(layer.layerId || layer.name)
        || !mapRef.getLayer(prevLayer.layerId || prevLayer.name)
      ) {
        return
      }

      mapRef.moveLayer(layer.layerId || layer.name, prevLayer.layerId || prevLayer.name)
    })
  }
}

onMounted(() => {
  if (map.value) {
    // map.value.on('styledata', moveLayers)
  }
})

onBeforeUnmount(() => {
  if (map.value) {
    // map.value.off('styledata', moveLayers)
  }
})
</script>

<template>
  <slot :reorder="moveLayers" />
</template>
