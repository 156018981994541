<script setup lang="ts">
import type { ColorOrPattern, Palette } from '@/utils/colors'

defineOptions({
  name: 'ChartLegendBadge',
})

const props = withDefaults(defineProps<{
  colorIndex: number
  color?: ColorOrPattern[] | ColorOrPattern
}>(), {
  colorIndex: 0,
})

const palette = inject<Ref<Palette | undefined>>('palette', ref())
const canvas = ref<HTMLCanvasElement>()
const computedColor = computed((): ColorOrPattern => {
  if (props.color) {
    if (Array.isArray(props.color)) {
      return props.color[0]
    }
    return props.color
  }

  return getColorByIndex(props.colorIndex, palette.value)
})

watch(
  computedColor,
  async (computedColor) => {
    if (typeof computedColor === 'string') {
      return
    }

    await nextTick()

    const ctx = get(canvas)?.getContext('2d')
    if (!ctx) {
      return
    }

    const pattern = computedColor as CanvasPattern
    ctx.beginPath()
    ctx.rect(0, 0, 16, 16)
    ctx.fillStyle = pattern
    ctx.fill()
  },
  { immediate: true },
)
</script>

<template>
  <div
    v-if="typeof computedColor === 'string'"
    class="h-4 w-4 rounded-full"
    :style="{
      'background-color': computedColor,
    }"
  />
  <div
    v-else
    class="h-4 w-4 overflow-hidden rounded-full"
  >
    <canvas
      ref="canvas"
      class="h-4 w-4"
      height="16"
      width="16"
    />
  </div>
</template>
