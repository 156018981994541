import defu from 'defu'

type Sliceable = any[] | string

export async function asyncExecByFrame<T = Sliceable>(
  item: Sliceable,
  itterator: (chunk: Sliceable, acc: T, item?: Sliceable) => T | Promise<T>,
  acc: T,
  options = {
    chunkSize: 1,
    timeout: 0,
  },
) {
  let promise = Promise.resolve(acc)
  let chunkHead = 0

  options = defu(options, { chunkSize: 1, timeout: 0 })

  while (chunkHead < item.length) {
    const chunkStart = chunkHead
    const chunkEnd = chunkStart + options.chunkSize

    promise = promise.then(accu => new Promise((resolve) => {
      requestAnimationFrame(async () => {
        const sliced = item.slice(chunkStart, chunkEnd)
        Promise.resolve(isPromise(itterator)
          ? await itterator(options.chunkSize === 1 ? sliced[0] : sliced, accu, item)
          : itterator(options.chunkSize === 1 ? sliced[0] : sliced, accu, item))
          .then(ret => resolve(ret))
      })
    }))

    chunkHead = chunkEnd
  }

  return promise
}
