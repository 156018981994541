export enum CyclabilityNetworkRoadTypes {
  Independant_cycle_and_pedestrian_track,
  Physically_separated_cycle_track,
  Dedicated_track,
  Dedicated_lanes,
  Pedestrian_track_with_cycling_allowed,
  Pedestrian_street,
  Living_street,
  Limited_access_roads,
  Shared_bus_lanes,
  Medium_and_low_speed_road,
  Medium_speed_roads,
  High_speed_roads,
  In_construction,
  Others,
}

export type CyclabilityNetworkRoadType = `${keyof typeof CyclabilityNetworkRoadTypes}`
