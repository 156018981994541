<script setup>
import { REGIONS } from '@/utils/constants'

defineOptions({
  name: 'HomepageMap',
})

defineProps({
  loading: Boolean,
  cities: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['selectCity'])

const { t } = useI18n()
const route = useRoute()
const { getCityNameLocalized } = useCitiesStore()

const regionCode = ref(RegionsCodes.EU)
const selectedRegion = ref()

watch(regionCode, (regionCode) => {
  selectedRegion.value = REGIONS.find(r => r.code === regionCode)
}, { immediate: true })

const regionsOptions = computed(() => {
  return REGIONS.map(region => ({
    text: t(region.name),
    value: region.code,
    icon: getRegionIcon(region.code),
  }))
})

function onClickOnMap({ event }) {
  if (event.originalEvent?.target?.className?.includes('city-marker')) {
    return
  }

  emit('selectCity', null)
}
</script>

<template>
  <MapLibre
    :center="selectedRegion.center"
    :zoom="selectedRegion.zoom"
    @click="onClickOnMap"
  >
    <MapLibreMarker
      v-for="city in cities"
      :key="`${city.name}`"
      :lng-lat="[city.center?.lng, city.center?.lat]"
      @click="
        marker => {
          marker.togglePopup()
          emit('selectCity', city.name)
        }
      "
      @mouseenter="marker => marker.togglePopup()"
      @mouseleave="marker => marker.togglePopup()"
    >
      <template #element>
        <div
          class="city-marker rounded-full shadow-marker ring-[3px] ring-inset ring-white hover:cursor-pointer"
          :class="{
            'bg-blue-500 h-4 w-4 ring-[3px]': city.availableInCD,
            'bg-[#d67fc6] h-3 w-3 ring-2': !city.availableInCD,
            'shadow-marker-selected': city.name === route.params.citySlug,
          }"
        />
      </template>
      <MapLibrePopup
        :text="getCityNameLocalized(city.name)"
        anchor="left"
        :offset="10"
        close-on-click
      />
    </MapLibreMarker>

    <slot />

    <MapLibreControl
      v-if="!loading"
      position="top-left"
      no-style
      class="!ml-4 !mt-6"
    >
      <div class="w-60">
        <DSelectPicker
          v-model="regionCode"
          :options="regionsOptions"
          :label="t('Geographical Area')"
          icon="map-marker"
        />
      </div>
    </MapLibreControl>

    <MapLibreControl
      v-if="loading"
      position="bottom-right"
    >
      <DLoader />
    </MapLibreControl>
  </MapLibre>
</template>
