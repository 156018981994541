<script setup lang="ts">
import type { DateRange } from '@/stores/filters'

defineOptions({
  name: 'CityCyclabilityBikesPerformance',
})

const { t } = useI18n()
const { city } = storeToRefs(useCityStore())
const { writeCache } = useWriteCacheForMetric('cityCyclabilityBikesPerformance')

const dateRange = {
  start: new Date(formatDate('2024-04-29', DATE_FORMAT)),
  end: new Date(formatDate('2024-06-29', DATE_FORMAT)),
}

const { translateDate } = useDateTranslation()

function translateRange(range: DateRange) {
  return {
    start: translateDate(new Date(range.start), DATES_FORMATS.FULL),
    end: translateDate(new Date(range.end), DATES_FORMATS.FULL),
  }
}

onMounted(() => {
  if (!city.value) {
    return
  }

  writeCache({
    city: city.value?.name,
  }, {
    date: dateRange.start,
    fleet: {
      total: {
        __typename: 'Percent',
        value: 8330,
        trend: {
          percent: 13,
          direction: Directions.UP,
          positive: true,
        },
      },
      vehicleTypes: {
        BIKE: 430,
        STATION: 7900,
      },
    },
    trips: {
      total: {
        __typename: 'Percent',
        value: 57310,
        trend: {
          percent: 1,
          direction: Directions.UP,
          positive: true,
        },
      },
      vehicleTypes: {
        BIKE: 1055,
        STATION: 56255,
      },
    },
  })
})
</script>

<template>
  <MetricCard
    kpi="cityCyclabilityBikesPerformance"
    :used-filters="['dateRange']"
  >
    <template #title>
      {{ t('cyclability.bikes_performance.title') }}
    </template>

    <template #range-subtitle>
      {{ t('dates.daterange_formated_human', translateRange(dateRange)) }} - {{ t('(VS same last period)') }}
    </template>

    <template #default="{ data }">
      <div class="flex flex-col xl:flex-row gap-4 justify-between">
        <div>
          <p class="text-sm">
            {{ t('cyclability.bikes_performance.avg_daily_fleet') }}
          </p>
          <TrendMetricWithVehiclesTypes
            :data="data.fleet.total"
            :vehicles-types="data.fleet.vehicleTypes"
            :accept="['BIKE', 'STATION']"
          />
        </div>
        <div>
          <p>{{ t('cyclability.bikes_performance.avg_daily_trips') }}</p>
          <TrendMetricWithVehiclesTypes
            :data="data.trips.total"
            :vehicles-types="data.trips.vehicleTypes"
            :accept="['BIKE', 'STATION']"
          />
        </div>
      </div>
    </template>
  </MetricCard>
</template>
