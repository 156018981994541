import { DATES_FORMATS } from '@/utils/constants'

import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

const dateFunc = {
  endOfDay,
  endOfWeek,
  endOfMonth,
  startOfDay,
  startOfWeek,
  startOfMonth,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
}

function prepareDateFromTimeMode(range, timeMode = TimeModes.DAY) {
  if (!range) {
    return null
  }

  return Object.entries(range).reduce((acc, [key, value]) => {
    acc[key] = dateFunc[`${key}Of${capitalize(timeMode)}`](value, {
      weekStartsOn: 1,
    })
    return acc
  }, {})
}

export function useCompareLabels() {
  const { translateDate } = useDateTranslation()
  const filtersStore = useFiltersStore()

  const labels = computed(() => {
    if (filtersStore.comparativeRange) {
      const cats = {
        range: prepareDateFromTimeMode(
          filtersStore.dateRange,
          filtersStore.timeMode,
        ),
        compare: prepareDateFromTimeMode(
          filtersStore.comparativeRange,
          filtersStore.timeMode,
        ),
      }

      const eachOfRangeInterval = dateFunc[
        `each${capitalize(filtersStore.timeMode)}OfInterval`
      ](cats.range, { weekStartsOn: 1 })
      const eachOfCompareInterval = dateFunc[
        `each${capitalize(filtersStore.timeMode)}OfInterval`
      ](cats.compare, { weekStartsOn: 1 })

      if (eachOfRangeInterval.length > eachOfCompareInterval.length) {
        return eachOfRangeInterval.map((date, index) => {
          const compareDate = eachOfCompareInterval[index]
          const rangeStr = translateDate(date, DATES_FORMATS.SHORT)

          if (compareDate) {
            return `${rangeStr} - ${translateDate(compareDate, DATES_FORMATS.SHORT)}`
          } else {
            return `${rangeStr} - -`
          }
        })
      } else {
        return eachOfCompareInterval.map((date, index) => {
          const rangeDate = eachOfRangeInterval[index]
          const compareStr = translateDate(date, DATES_FORMATS.SHORT)

          if (rangeDate) {
            return `${translateDate(rangeDate, DATES_FORMATS.SHORT)} / ${compareStr}`
          } else {
            return `- / ${compareStr}`
          }
        })
      }
    } else {
      return filtersStore.dateRangeStr
    }
  })

  return {
    labels,
  }
}
