import type { CityData } from './cities'

import type { Provider } from './provider'
import { defineStore } from 'pinia'

export const useProvidersStore = defineStore('providers', {
  state: () => {
    return {
      providers: [] as Provider[],
    }
  },

  actions: {
    saveProviders(providers: Provider[] = []) {
      this.providers = providers.map(provider => ({
        ...provider,
        nameLocalized: provider.name,
      }))
    },
  },

  getters: {
    providersBySlug: (state) => {
      return state.providers.reduce((acc, provider) => {
        acc[provider.slug] = provider

        return acc
      }, {} as Record<string, Provider>)
    },
    getProviderCitiesByCountriesInRegion() {
      const citiesStore = useCitiesStore()
      const citiesByCountries = citiesStore.citiesByCountries

      return (slug: string, region: string | null = null) => {
        const provider = this.getProvider(slug)

        if (!provider) {
          return {}
        }

        const { cities, countries } = provider

        return countries.filtered?.reduce((acc, country) => {
          cities.filtered?.forEach((city) => {
            const cityDetail = citiesByCountries[country]?.find(
              (c: CityData) => c.name === city,
            )

            if (
              cityDetail
              && ((region && cityDetail.region === region) || !region)
            ) {
              if (!acc[country]) {
                acc[country] = []
              }

              acc[country].push(cityDetail)
            }
          })

          return acc
        }, {} as Record<string, CityData[]>)
      }
    },
    getProviderCitiesByRegions() {
      return (slug: string) => {
        const citiesStore = useCitiesStore()
        const provider = this.getProvider(slug)

        if (!provider) {
          return {}
        }

        const { cities, regions } = provider

        return regions?.filtered?.reduce((acc, region) => {
          if (!acc[region]) {
            acc[region] = []
          }

          cities.filtered?.forEach((city) => {
            const cityDetail = citiesStore.getCity(city)

            if (cityDetail.region === region) {
              acc[region].push(city)
            }
          })

          return acc
        }, {} as Record<string, string[]>)
      }
    },
    getProvider() {
      const providersBySlug = this.providersBySlug
      return (slug: string) => providersBySlug[slug] || null
    },
    isProviderLocked() {
      // by default we assume that all providers are unlocked, if a provider is not in the list, it's locked
      return (slug: string) => {
        const provider = this.getProvider(slug)
        return provider?.unlocked === false || !provider
      }
    },
  },
})

// make sure to pass the right store definition, `useProvidersStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProvidersStore, import.meta.hot))
}
