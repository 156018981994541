import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')

  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  return forward(operation)
})

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_CAPTAIN_API}/v1`,
})

const apolloClient = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default apolloClient
