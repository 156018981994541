import addPaymentMethodMutation from '@/graphql/mutations/addPaymentMethod.gql'
import addSubscriptionMutation from '@/graphql/mutations/addSubscription.gql'
import cancelSubscriptionMutation from '@/graphql/mutations/cancelSubscription.gql'
import confirmSubscriptionMutation from '@/graphql/mutations/confirmSubscription.gql'
import createSetupIntentMutation from '@/graphql/mutations/createSetupIntent.gql'
import paySubscriptionMutation from '@/graphql/mutations/paySubscription.gql'
import updateSubscriptionMutation from '@/graphql/mutations/updateSubscription.gql'

export function useSubscription() {
  const { loadUser } = useUserStore()
  const { organization, subscription } = storeToRefs(useUserStore())

  const defaultOptions = () => {
    return {
      variables: {
        organizationId: organization.value.id,
      },
      clientId: CAPTAIN,
    }
  }

  const { mutate: confirmSubscription, onDone: onConfirmSubscriptionDone }
    = useMutation(confirmSubscriptionMutation, defaultOptions)

  onConfirmSubscriptionDone(() => loadUser())

  const { mutate: cancelSubscription, onDone: onCancelSubscriptionDone }
    = useMutation(cancelSubscriptionMutation, () => ({
      ...defaultOptions(),
      variables: {
        organizationId: organization.value.id,
        billing: true,
        billingPeriods: 1,
      },
    }))

  onCancelSubscriptionDone(() => loadUser())

  const { mutate: addPaymentMethod, onDone: onAddPaymentMethodDone }
    = useMutation(addPaymentMethodMutation, defaultOptions)

  onAddPaymentMethodDone(() => loadUser())

  const { mutate: updateSubscription, onDone: onUpdateSubscriptionDone }
    = useMutation(
      () =>
        subscription.value
          ? updateSubscriptionMutation
          : addSubscriptionMutation,
      defaultOptions,
    )

  onUpdateSubscriptionDone(() => loadUser())

  const { mutate: createSetupIntent } = useMutation(
    createSetupIntentMutation,
    defaultOptions,
  )

  const { mutate: paySubscription, onDone: onPaySubscriptionDone }
    = useMutation(paySubscriptionMutation, defaultOptions)

  onPaySubscriptionDone(() => loadUser())

  return {
    confirmSubscription,
    cancelSubscription,
    addPaymentMethod,
    updateSubscription,
    createSetupIntent,
    paySubscription,
  }
}
