<script setup lang="ts">
import type { AnySerie, DimensionType } from '@/types/charts.types'
import type { Color } from '@/utils/colors'

defineOptions({
  name: 'ChartLegend',
})

const props = withDefaults(defineProps<ChartLegendProps>(), {
  series: () => [],
  modelValue: () => [],
  selectable: true,
})

const emit = defineEmits(['update:modelValue', 'reset'])

interface ChartLegendProps {
  dimension?: DimensionType
  series: AnySerie[]
  subLegend?: (data: AnySerie) => string | null
  modelValue?: string[]
  sortBy?: keyof AnySerie
  palette?: Color[]
  selectable?: boolean
}

const visible = useVModel(props, 'modelValue', emit)
const gtm = useGtm()

const { palette } = toRefs(props)
provide('palette', palette)

const metricName = inject('metricName', 'no metric name passed')

const seriesSorted = computed(() => {
  const { sortBy, series } = props

  if (sortBy && !Array.isArray(series[0]?.[sortBy])) {
    return series.filter((serie) => {
      return serie.name !== undefined
    })
      .slice()
      .sort(sortByAttr(sortBy))
  }

  return props.series
})

function onToggleItem(name: string) {
  if (!props.selectable) {
    return
  }

  const array = [...visible.value]
  const index = array.indexOf(name)

  if (index === -1) {
    array.push(name)
  } else {
    array.splice(index, 1)
  }

  visible.value = array
}

function onSelectItem(name: string) {
  if (!props.selectable) {
    return
  }

  const array = visible.value

  if (array.length === 1 && array.includes(name)) {
    emit('reset')
  } else {
    visible.value = [name]
  }
}

function onClick() {
  if (gtm?.enabled()) {
    gtm.trackEvent({
      event: 'clic_metric_legend',
      category: 'data',
      action: 'click',
      label: metricName,
    })
  }
}
</script>

<template>
  <div
    class="flex flex-wrap gap-x-4 gap-y-2"
    @click="onClick"
  >
    <ChartLegendItem
      v-for="(data, index) in seriesSorted"
      :key="data.name"
      :data="data"
      :dimension="dimension"
      :data-index="data.dataIndex ?? index"
      :disabled="selectable && !visible.includes(data.name || '')"
      :class="{
        'cursor-pointer': selectable,
      }"
      @select="onSelectItem"
      @toggle="onToggleItem"
    >
      <span
        v-if="subLegend"
        class="text-nowrap text-grey-300"
      >{{
        subLegend(data)
      }}</span>
    </ChartLegendItem>
  </div>
</template>
