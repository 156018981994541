import type { Provider } from '@/stores/provider'
import type { DimensionType } from '@/types/charts.types'
import type { AnyVehicleTypes } from '@/types/vehicleTypes.types'
import type { FormatDateOptions } from '@/utils/formats'
import type { Composer } from 'vue-i18n'
import { DimensionTypes } from '@/types/charts.types'
import { ENUM_LOCATION_TYPES } from '@/utils/constants'

enum DataKeyPaths {
  CITIES = 'cities',
  WIKIDATA = 'wikidata',
  COUNTRIES = 'countries',
  CONTINENTS = 'continents',
  REGIONS = 'regions',
  STATES = 'states',
  LOCALES = 'locales',
  TIMEMODE = 'timeMode',
  GEOMETRY = 'geometry',
}

type DataKeyPath = `${DataKeyPaths}`
type AnyOrArray<T> = T | T[]
type StrOrArray = AnyOrArray<string>

export function useProviderName(i18n?: Composer) {
  if (i18n === undefined) {
    i18n = useI18n()
  }

  const { t } = i18n
  const providersStore = useProvidersStore()

  const providersBySlug = computed(
    () =>
      providersStore.providers?.reduce((acc, provider) => {
        acc[provider.slug] = provider

        return acc
      }, {} as Record<string, Provider>) || {},
  )

  function providerName(provider: Provider) {
    return provider.name || capitalize(provider.slug)
  }

  function providerNameBySlug(slug: string, returnNull = false) {
    const provider = providersBySlug.value[slug]

    if (provider) {
      return provider.name || capitalize(slug)
    }

    return returnNull ? null : capitalize(slug)
  }

  function providerNameBySlugOrNA(slug: string) {
    return providerNameBySlug(slug, true) || t('N/A')
  }

  return {
    providerNameBySlugOrNA,
    providerNameBySlug,
    providerName,
  }
}

export function useExamplesTranslation(i18n?: Composer) {
  if (i18n === undefined) {
    i18n = useI18n()
  }

  const { t, te } = i18n

  const translateExample = (example: string): string => {
    if (Array.isArray(example)) {
      return example.map(translateExample).join(' - ')
    }

    const field = te(`examples.${example}`) ? `examples.${example}` : example
    return `${t('examples.eg')} ${t(field)}`
  }

  return {
    translateExample,
  }
}

export function useLabelTranslation(i18n?: Composer) {
  if (i18n === undefined) {
    i18n = useI18n()
  }

  const { t, te } = i18n
  const { providerNameBySlug } = useProviderName(i18n)
  const citiesStore = useCitiesStore()

  function getDataKeyPath(type: DataKeyPath, name: string) {
    if (typeof name === 'string') {
      return `data.${type}.${name.trim()}`
    }
    return false
  }

  function translationExists(keypath: string, withFallback = true) {
    if (withFallback) {
      return te(keypath) || te(keypath, 'en')
    } else {
      return te(keypath)
    }
  }

  function translationDataExists(type: DataKeyPath, name: string, withFallback = true) {
    const keypath = getDataKeyPath(type, name)

    return keypath && translationExists(keypath, withFallback)
  }

  function translateIfExists(path: string, nope?: string) {
    return translationExists(path) ? t(path) : nope || path
  }

  function translateFromData(type: DataKeyPath, name: string, callback?: (v: string) => string) {
    const keypath = getDataKeyPath(type, name)

    if (keypath && translationExists(keypath)) {
      return t(keypath)
    }

    return callback instanceof Function ? callback(name) : name
  }

  const translateCity = (name: string) => translateFromData('cities', name)
  const translateWikidataId = (id: string) => translateFromData('wikidata', id)
  const translateCountry = (countryCode: string) => translateFromData('countries', countryCode)
  const translateContinent = (name: string) => translateFromData('continents', name)
  const translateRegion = (name: string) => translateFromData('regions', name)
  const translateState = (name: string) => translateFromData('states', name)
  const translateLocale = (name: string) => translateFromData('locales', name)

  function translateLocation(name: StrOrArray, type = null): string {
    if (!name) {
      return ''
    }

    if (Array.isArray(name)) {
      return name.map(n => translateLocation(n, type)).join(' - ')
    }

    if (!Number.isNaN(Number(name))) {
      return name
    }

    if (
      name.match(/^X-/)
      || type === ENUM_LOCATION_TYPES.region
      || te(`data.regions.${name}`)
    ) {
      // Region
      return translateRegion(name.replace(/^X-/, ''))
    }

    if (
      type === ENUM_LOCATION_TYPES.continent
      || te(`data.continents.${name}`)
    ) {
      // Continent
      return translateContinent(name)
    }

    if (
      name.match(/^[A-Z][A-Z]$/)
      || type === ENUM_LOCATION_TYPES.country
      || te(`data.countries.${name}`)
      || citiesStore.getCountryCode(name)
    ) {
      // Country

      return citiesStore.getCountryNameLocalized(name)
    }

    if (
      name.match(/^[A-Z][A-Z]-[A-Z][A-Z]$/)
      || type === ENUM_LOCATION_TYPES.state
      || te(`data.states.${name}`)
    ) {
      // State
      return translateState(name)
    }

    // WikidataId
    const match = /^(Q\d+):(.+)/.exec(name)
    if (match) {
      const id = match[1]
      const name = match[2]

      const cityDiveCity = citiesStore.getCityByWikidataId(id)

      if (cityDiveCity?.nameLocalized) {
        return cityDiveCity.nameLocalized
      }

      return translateWikidataId(name)
    }

    // City from CityDive
    const cityDiveCity = citiesStore.getCity(name)

    if (cityDiveCity?.nameLocalized) {
      return cityDiveCity.nameLocalized
    }

    // try to translate city name
    return translateCity(name)
  }

  function translateVehicleType(type: StrOrArray, plural = false): string {
    if (Array.isArray(type)) {
      return type.map(t => translateVehicleType(t, plural)).join(' - ')
    }

    if (isVehicleType(type)) {
      const vehicleTypeLabel = getVehicleTypeLabel(type as AnyVehicleTypes, plural) || type

      const field = `data.vehicleTypeLabels.${vehicleTypeLabel}`
      return translateIfExists(field, vehicleTypeLabel)
    }

    return type
  }

  // translate metrics label, can be a provider, a country or a city name
  function tryToTranslateLabel(label: StrOrArray): string {
    if (Array.isArray(label)) {
      return label.map(tryToTranslateLabel).join(' - ')
    }

    const providerName = providerNameBySlug(label, true)
    if (providerName) {
      return providerName
    }

    const countryOrCity = translateLocation(label)
    if (countryOrCity !== label) {
      return countryOrCity
    }

    return t(label)
  }

  function translateLabel(label: StrOrArray, dimension: DimensionType = DimensionTypes.UNKNOWN): string {
    if (Array.isArray(label)) {
      return label.map(l => translateLabel(l, dimension)).join(' - ')
    }

    switch (dimension) {
      case DimensionTypes.PROVIDER:
        return providerNameBySlug(label, true) || label
      case DimensionTypes.LOCATION:
        return translateLocation(label)
      case DimensionTypes.VEHICLETYPE:
        return translateVehicleType(label, true)
      case DimensionTypes.AUTO:
        return tryToTranslateLabel(label)
      default:
        return t(label)
    }
  }

  function translateTag(tag: StrOrArray): string {
    if (Array.isArray(tag)) {
      return tag.map(translateTag).join(' - ')
    }

    const field = `data.tags.${tag}`
    return translateIfExists(field, capitalize(tag))
  }

  return {
    translationExists,
    translateIfExists,
    translateFromData,
    translationDataExists,
    translateLabel,
    translateTag,
    translateVehicleType,
    translateLocation,
    translateCity,
    translateWikidataId,
    translateCountry,
    translateContinent,
    translateRegion,
    translateState,
    translateLocale,
  }
}

export function useDateTranslation(i18n?: Composer) {
  if (i18n === undefined) {
    i18n = useI18n()
  }

  const { locale } = i18n

  function translateDate(date: string | number | Date, format: FormatDateOptions): string {
    return formatDate(date, format, locale.value)
  }

  return {
    translateDate,
  }
}
