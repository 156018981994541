<script setup>
const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  list: { type: Array, default: () => [] },
  deepValues: {
    type: Function,
    default: v => v,
  },
  locked: Boolean,
  loading: Boolean,
  hideInput: Boolean,
  multiple: Boolean,
  variant: {
    type: String,
    default: 'default',
    validation: value => ['default', 'secondary'].includes(value),
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const tags = useVModel(props, 'modelValue', emit)
const attrs = useAttrs()

const cachedList = ref([])
const search = ref('')
const searchInput = ref(null)
const isInputVisible = ref(!props.hideInput)

const reference = ref(null)
const floating = ref(null)

const { isOpen, floatingStyles, width } = useOpenAndFloat(reference, floating)
const { onEnter, onEscape } = useInputShortcut(reference)

const listFiltered = computed(() => {
  const searchRef = get(search)

  return props.list
    .filter((item) => {
      if (!props.multiple && get(tags).includes(props.deepValues(item.value))) {
        return false
      }

      const regex = new RegExp(`${searchRef}`, 'i')
      return (
        item.label?.match(regex) || props.deepValues(item.value)?.match(searchRef)
      )
    })
    .sort(sortByAttr('label'))
})

watch(isOpen, (nextIsOpen) => {
  if (!nextIsOpen) {
    if (props.hideInput) {
      closeSearch()
    }
  }
})

function onSelect(item) {
  if (!item.locked) {
    if (!props.multiple) {
      closeSearch()
    }

    if (props.multiple && get(tags).includes(item.value)) {
      onRemove(item.value)
    } else {
      get(tags).push(item.value)
    }
  }
}

function onRemove(removed) {
  const tagsRef = get(tags)

  tagsRef.splice(
    tagsRef.findIndex(
      tag => props.deepValues(removed) === props.deepValues(tag),
    ),
    1,
  )
}

async function onPlus() {
  if (props.hideInput) {
    set(isInputVisible, true)
  }

  await nextTick()
  get(reference).$refs.input.focus()
}

function closeSearch() {
  set(search, '')
  set(isOpen, false)

  if (props.hideInput) {
    set(isInputVisible, false)
  }
}

onEnter(() => {
  onSelect(get(listFiltered)[0])
})

onEscape(() => {
  closeSearch()
})

whenever(
  () => props.list,
  () => {
    const cachedListRef = get(cachedList)
    const tagsRef = get(tags)

    set(cachedList, [
      ...cachedListRef.filter(({ value }) =>
        tagsRef.includes(props.deepValues(value)),
      ),
      ...props.list.filter(
        ({ value }) =>
          !cachedListRef.find(item => props.deepValues(item.value) === value),
      ),
    ])
  },
  { immediate: true, deep: true },
)
</script>

<template>
  <div
    ref="searchInput"
    class="flex flex-row flex-wrap items-center gap-1"
  >
    <div
      v-if="isInputVisible"
      class="relative"
      :class="{
        'mb-2 w-full': !hideInput,
        'order-last min-w-[150px] flex-1': hideInput,
      }"
    >
      <DInputText
        ref="reference"
        v-model.lazy.trim="search"
        type="search"
        :disabled="locked"
        v-bind="attrs"
        size="sm"
        @focus="isOpen = true"
      />
    </div>

    <Teleport to="body">
      <div
        v-if="isOpen"
        ref="floating"
        :style="[floatingStyles, { width: `${width}px` }]"
        class="z-popup max-h-32 w-full divide-y overflow-auto border border-grey-200 bg-white shadow-lg"
      >
        <template v-if="!loading">
          <p
            v-if="!list.length"
            class="flex items-center px-2 py-2 text-center"
          >
            {{ t('No tags to select') }}
          </p>
          <div
            v-for="item in listFiltered"
            :key="deepValues(item.value)"
            class="flex items-center hover:bg-grey-100"
            :class="{
              'cursor-pointer': !item.locked,
              'text-grey-300': item.locked,
            }"
          >
            <DInputCheckbox
              v-if="multiple"
              v-model="tags"
              :value="item.value"
              :disabled="item.locked"
              class="px-1 mr-0"
            />
            <div
              class="flex flex-1 items-center justify-between pl-1 pr-2 py-2"
              @click="onSelect(item)"
            >
              {{ item.label }}
              <DIcon
                v-if="item.locked"
                path="cadenas"
                size="sm"
              />
            </div>
          </div>
        </template>
        <DLoader
          v-else
          size="sm"
        />
      </div>
    </Teleport>

    <DInputTagItem
      v-for="tag in tags"
      :key="tag"
      :variant="variant"
      :warning="!list.find(item => deepValues(item.value) === deepValues(tag))"
      @remove="onRemove(tag)"
    >
      {{
        cachedList.find(item => deepValues(item.value) === deepValues(tag))
          ?.label || deepValues(tag)
      }}
    </DInputTagItem>

    <DIconButton
      v-if="hideInput && !isInputVisible"
      class="flex-none !h-6 !w-6"
      :class="{
        '!bg-teal-800': variant === 'secondary',
      }"
      path="plus"
      size="sm"
      icon-size="xs"
      variant="fill-primary"
      @click="onPlus"
    />
  </div>
</template>
