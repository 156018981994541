import captainClient from '@/graphql/captain'
import cityDiveClient from '@/graphql/cityDive'
import { provideApolloClients } from '@vue/apollo-composable'

export const clients = {
  default: cityDiveClient,
  captain: captainClient,
}

export default provideApolloClients(clients)

export async function resetCityDive() {
  await cityDiveClient.cache.reset()
  await cityDiveClient.clearStore()
}

export async function resetCaptain() {
  await captainClient.cache.reset()
  await captainClient.clearStore()
}

export async function resetCache() {
  await resetCityDive()
  await resetCaptain()
}
