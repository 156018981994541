<script setup lang="ts">
import type { CyclabilityData } from '@/composables/cyclability'
import { ROAD_TYPES } from '@cd/screen-data'

defineOptions({
  name: 'CyclabilityMapLegends',
})

const props = defineProps<CyclabilityMapSegmentsProps>()

interface CyclabilityMapSegmentsProps {
  data: CyclabilityData
  isLoading?: boolean
}

const { t } = useI18n()
const [DefineTemplate, ReuseTemplate] = createReusableTemplate()

const expanded = ref<string>()

onMounted(() => {
  expanded.value = props.data.roads ? 'roads' : props.data.areas ? 'areas' : 'network'
})
</script>

<template>
  <DefineTemplate v-if="data">
    <DLoader v-if="isLoading" />

    <div class="flex flex-col gap-4">
      <div v-if="data.areas">
        <p
          class="mb-2 cursor-pointer"
          @click="expanded = expanded === 'areas' ? undefined : 'areas'"
        >
          <DIcon
            :name="expanded === 'areas' ? 'chevron-up' : 'chevron-down'"
            size="sm"
          />
          {{ t('Statistical area') }}<br>
          <small class="text-grey-500">{{ t(`cyclability.data.areas.${data.areas.metric}`) }}</small>
        </p>
        <MapLegend
          :class-breaks="data.areas.classbreaks"
          :inline="!(expanded === 'areas')"
        >
          <template
            v-if="data.areas.labels"
            #label="{ index }"
          >
            {{ data.areas.labels?.[index] }}
          </template>
        </MapLegend>
      </div>

      <div v-if="data.network">
        <p
          class="mb-2  cursor-pointer"
          @click="expanded = expanded === 'network' ? undefined : 'network'"
        >
          <DIcon
            :name="expanded === 'network' ? 'chevron-up' : 'chevron-down'"
            size="sm"
          />
          {{ t('Cycling network') }}<br>
          <small class="text-grey-500">{{ t(`cyclability.data.network.${data.network.metric}`) }}</small>
        </p>
        <MapLegend
          :class-breaks="data.network.classbreaks.slice().sort((ac, bc) => {
            return Object.values(CyclabilityNetworkRoadTypes).indexOf(ROAD_TYPES[ac.from])
              - Object.values(CyclabilityNetworkRoadTypes).indexOf(ROAD_TYPES[bc.from])
          })"
          :inline="!(expanded === 'network')"
        >
          <template
            v-if="data.network.labels"
            #label="{ index }"
          >
            {{ data.network.labels?.[index] }}
          </template>
        </MapLegend>
      </div>

      <div v-if="data.roads">
        <p
          class="mb-2   cursor-pointer"
          @click="expanded = expanded === 'roads' ? undefined : 'roads'"
        >
          <DIcon
            :name="expanded === 'roads' ? 'chevron-up' : 'chevron-down'"
            size="sm"
          />
          {{ t('Path metric') }}<br>
          <small class="text-grey-500">{{ t(`cyclability.data.paths.${data.roads.metric}`) }}</small>
        </p>
        <MapLegend
          :class-breaks="data.roads.classbreaks"
          :inline="!(expanded === 'roads')"
        >
          <template
            v-if="data.roads.labels"
            #label="{ index }"
          >
            {{ data.roads.labels?.[index] }}
          </template>
        </MapLegend>
      </div>
    </div>
  </DefineTemplate>

  <SidebarMobileControls>
    <template #default="{ isMobile }">
      <MapLibreControl
        v-if="!isMobile"
        position="bottom-left"
      >
        <ReuseTemplate />
      </MapLibreControl>
    </template>

    <template #mobile>
      <ReuseTemplate />
    </template>
  </SidebarMobileControls>
</template>
